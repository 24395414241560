import { css, CSSProp, DefaultTheme } from 'styled-components';

export const sizes: { [key: string]: number } = {
  mobile: 320,
  tablet: 768,
  desktop: 1250,
};

interface IMedia {
  mobile: (...args: string[]) => CSSProp | undefined;
  tablet: (...args: string[]) => CSSProp | undefined;
  desktop: (...args: string[]) => CSSProp | undefined;
}

const media: IMedia = {
  mobile: () => undefined,
  tablet: () => undefined,
  desktop: () => undefined,
};

Object.keys(sizes).reduce((acc: IMedia, label: string) => {
  switch (label) {
    case 'desktop':
      acc.desktop = (...args: string[]) => css`
        @media only screen and (min-width: ${sizes.desktop}px) {
          ${args}
        }
      `;
      break;
    case 'tablet':
      acc.tablet = (...args: string[]) => css`
        @media only screen and (min-width: ${sizes.tablet}px) and (max-width: ${sizes.desktop - 1}px) {
          ${args}
        }
      `;
      break;
    case 'mobile':
      acc.mobile = (...args: string[]) => css`
        @media only screen and (max-width: ${sizes.tablet - 1}px) {
          ${args}
        }
      `;
      break;
    default:
      break;
  }
  return acc;
}, media);

export const colors = {
  bg: '#191a1a',
  bg2: '#191a1a',
  bg3: '#1a1f24',
  bg4: '#333743',
  bg5: 'rgba(41, 45, 56, 0.50)',
  bg6: 'rgba(41, 45, 56, 0.50)',
  text: '#ffffff',
  headerBorder: '#333743',
  footerText: '#9ba1b2',
  gray: '#9ba1b2',
  darkGray: '#333743',
  orange: '#ff8718',
  red: '#dc3c3c',
  white: '#ffffff',
  mapPanelBg: 'linear-gradient(173deg, #333743 0%, #292D38 100%)',
  mapPanelItemShadow: '3px 2px 5px 0px rgba(0, 0, 0, 0.3) inset, -2px -2px 2px -1px rgba(155, 161, 178, 0.2)',
  locationDivider: 'rgba(155, 161, 178, 0.50)',
  memberTitle: '#ffffff',
  memberImageShadow: '5px 5px 15px 0px rgba(41, 45, 56, 0.20)',
  memberText: '#ffffff',
  aboutText1: '#9ba1b2',
  checkboxBorder: '#9ba1b2',
  checkboxDisabledBg: '#333743',
  aboutMapLabelBg: '#FFFFFF',
  aboutMapLabelText: '#1a1f24',
  timelapseBg: 'rgba(51, 55, 67, 0.7)',
};

// 2차년도 색깔
export const newColors = {
  negative: '#dc3c3c',
  positive: '#45d985',
  primary: '#ff8718',
  secondary: '#9e71ff',
  dark1: '#0a0b0e',
  dark2: '#232630',
  dark3: '#333847',
  grey1: '#8e96ae',
  grey2: '#c6cbd7',
  white: '#ffffff',
  black: '#000000',
};

const theme: DefaultTheme = { colors, newColors, media };

export type TColors = typeof colors;
export type TNewColors = typeof newColors;
export default theme;
