import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoWhiteIcon } from '@assets/icons/header/logo_white_icon.svg';

const Main = () => {
  return (
    <Container>
      <VideoWrapper>
        <Video playsInline loop autoPlay muted>
          <source src={'/video/main_notext.webm'} type="video/webm" />
          <source src={'/video/main.mp4'} type="video/mp4" />
        </Video>
        <Overlay>
          <LogoWhiteIcon width={300} />
          <Text>
            <div>
              <span className="bold">탄소 배출 및 흡수에 관한 모든 데이터를 통합</span>하여
            </div>
            <div>누구나 쉽고 빠르게 주거 지역의</div>
            <div>배출량/흡수량을 확인할 수 있는 시스템</div>
          </Text>
        </Overlay>
      </VideoWrapper>
    </Container>
  );
};

export default Main;

const Container = styled.div`
  width: 100%;
  height: calc(100% - 60px);
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  z-index: 1;
`;

const Text = styled.div`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.White};
  font-size: 26px;
  line-height: 38px;

  span.bold {
    font-weight: 700;
  }
`;
