import ConfirmButton from '@components/common/ConfirmButton';
import CarbonCategorySelector from '@components/common/selector/carbonCategorySelector/CarbonCategorySelector';
import TenseSelector from '@components/common/selector/TenseSelector';
import LocationSelector from '@components/common/selector/locationSelector/LocationSelector';
import { newColors } from '@styles/theme';
import styled from 'styled-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import useDashboardStore, { TCarbonRanking } from 'stores/dashboardStore';
import { TCarbonSourceDataType } from 'interfaces/mapInterface';
import { convertArrayToLocation, convertLocationToArray } from '@utils/convertLocationToArray';

export type TCarbonRankingInput = {
  location: string[];
  tense: string;
  carbonCategory: TCarbonSourceDataType[];
};

const CarbonRankingSearchBar = () => {
  const { carbonRanking, setCarbonRanking } = useDashboardStore();

  const defaultValues: TCarbonRankingInput = {
    location: convertLocationToArray(carbonRanking.location),
    tense: carbonRanking.tense,
    carbonCategory: carbonRanking.dataType,
  };

  const methods = useForm<TCarbonRankingInput>({ defaultValues, mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const handleFormSubmit: SubmitHandler<TCarbonRankingInput> = (formData) => {
    const { location, carbonCategory, tense } = formData;
    const dataCondition: TCarbonRanking = {
      location: convertArrayToLocation(location),
      tense,
      dataType: carbonCategory,
    };

    setCarbonRanking(dataCondition);
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <SelectorContainer>
          <LocationSelector
            selectorId="ranking-location-selector"
            inputName="location"
            width="420px"
            isThirdItem={false}
            backgroundColor={newColors.dark2}
          />
          <TenseSelector selectorId="ranking-tense-selector" inputName="tense" backgroundColor={newColors.dark2} width="274px" />
          <CarbonCategorySelector
            selectorId="ranking-carbon-category-selector"
            inputName="carbonCategory"
            backgroundColor={newColors.dark2}
            width="274px"
          />
        </SelectorContainer>
        <ConfirmButton text="검색하기" width="150px" disabled={!isValid} />
      </Form>
    </FormProvider>
  );
};

export default CarbonRankingSearchBar;

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border: 2px solid ${({ theme }) => theme.newColors.dark3};
  border-radius: 8px;
`;

const SelectorContainer = styled.div`
  display: flex;
  gap: 10px;
`;
