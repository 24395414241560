import { useQuery } from '@tanstack/react-query';
import { getDistrictsData } from 'api/districtApi';

export const useDistrictsQuery = (ctp_cd: number, enabled: boolean) => {
  return useQuery({
    queryKey: ['districts', ctp_cd],
    queryFn: () => getDistrictsData(ctp_cd),
    enabled,
    staleTime: Infinity,
    gcTime: Infinity,
  });
};
