import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { IChartDataType } from 'interfaces/chartInterface';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

const options = {
  maintainAspectRatio: false,
  responsive: false,
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderRadius: { bottomLeft: 0, bottomRight: 3, topLeft: 0, topRight: 3 },
    },
  },
  scales: {
    x: { border: { display: false }, ticks: { padding: 10 } },
    y: { grid: { display: false }, ticks: { crossAlign: 'far' as 'far' | 'center' | 'near' | undefined } },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
        },
        drag: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        mode: 'x' as 'x' | 'y' | 'xy',
      },
    },
  },
};

type Props = {
  data: { labels: string[]; datasets: IChartDataType[] };
};

const BarChart = ({ data }: Props) => {
  return <Bar options={options} data={data} width={1200} height={350} style={{ position: 'relative', height: '350px', width: '100%' }} />;
};

export default BarChart;
