type TLocationDataType = {
  [key: string]: {
    ko: string;
    en: string;
    disabled?: boolean;
    children?: TLocationDataType;
  };
};

export const locationData = {
  '11000000': {
    ko: '서울특별시',
    en: '서울특별시',
    children: {
      '0': {
        ko: '전체',
        en: '전체',
        children: { '0': { ko: '전체', en: 'all' } },
      },
      '11110000': {
        ko: '종로구',
        en: '종로구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11110101': { ko: '청운동', en: '청운동' },
          '11110102': { ko: '신교동', en: '신교동' },
          '11110103': { ko: '궁정동', en: '궁정동' },
          '11110104': { ko: '효자동', en: '효자동' },
          '11110105': { ko: '창성동', en: '창성동' },
          '11110106': { ko: '통의동', en: '통의동' },
          '11110107': { ko: '적선동', en: '적선동' },
          '11110108': { ko: '통인동', en: '통인동' },
          '11110109': { ko: '누상동', en: '누상동' },
          '11110110': { ko: '누하동', en: '누하동' },
          '11110111': { ko: '옥인동', en: '옥인동' },
          '11110112': { ko: '체부동', en: '체부동' },
          '11110113': { ko: '필운동', en: '필운동' },
          '11110114': { ko: '내자동', en: '내자동' },
          '11110115': { ko: '사직동', en: '사직동' },
          '11110116': { ko: '도렴동', en: '도렴동' },
          '11110117': { ko: '당주동', en: '당주동' },
          '11110118': { ko: '내수동', en: '내수동' },
          '11110119': { ko: '세종로', en: '세종로' },
          '11110120': { ko: '신문로1가', en: '신문로1가' },
          '11110121': { ko: '신문로2가', en: '신문로2가' },
          '11110122': { ko: '청진동', en: '청진동' },
          '11110123': { ko: '서린동', en: '서린동' },
          '11110124': { ko: '수송동', en: '수송동' },
          '11110125': { ko: '중학동', en: '중학동' },
          '11110126': { ko: '종로1가', en: '종로1가' },
          '11110127': { ko: '공평동', en: '공평동' },
          '11110128': { ko: '관훈동', en: '관훈동' },
          '11110129': { ko: '견지동', en: '견지동' },
          '11110130': { ko: '와룡동', en: '와룡동' },
          '11110131': { ko: '권농동', en: '권농동' },
          '11110132': { ko: '운니동', en: '운니동' },
          '11110133': { ko: '익선동', en: '익선동' },
          '11110134': { ko: '경운동', en: '경운동' },
          '11110135': { ko: '관철동', en: '관철동' },
          '11110136': { ko: '인사동', en: '인사동' },
          '11110137': { ko: '낙원동', en: '낙원동' },
          '11110138': { ko: '종로2가', en: '종로2가' },
          '11110139': { ko: '팔판동', en: '팔판동' },
          '11110140': { ko: '삼청동', en: '삼청동' },
          '11110141': { ko: '안국동', en: '안국동' },
          '11110142': { ko: '소격동', en: '소격동' },
          '11110143': { ko: '화동', en: '화동' },
          '11110144': { ko: '사간동', en: '사간동' },
          '11110145': { ko: '송현동', en: '송현동' },
          '11110146': { ko: '가회동', en: '가회동' },
          '11110147': { ko: '재동', en: '재동' },
          '11110148': { ko: '계동', en: '계동' },
          '11110149': { ko: '원서동', en: '원서동' },
          '11110150': { ko: '훈정동', en: '훈정동' },
          '11110151': { ko: '묘동', en: '묘동' },
          '11110152': { ko: '봉익동', en: '봉익동' },
          '11110153': { ko: '돈의동', en: '돈의동' },
          '11110154': { ko: '장사동', en: '장사동' },
          '11110155': { ko: '관수동', en: '관수동' },
          '11110156': { ko: '종로3가', en: '종로3가' },
          '11110157': { ko: '인의동', en: '인의동' },
          '11110158': { ko: '예지동', en: '예지동' },
          '11110159': { ko: '원남동', en: '원남동' },
          '11110160': { ko: '연지동', en: '연지동' },
          '11110161': { ko: '종로4가', en: '종로4가' },
          '11110162': { ko: '효제동', en: '효제동' },
          '11110163': { ko: '종로5가', en: '종로5가' },
          '11110164': { ko: '종로6가', en: '종로6가' },
          '11110165': { ko: '이화동', en: '이화동' },
          '11110166': { ko: '연건동', en: '연건동' },
          '11110167': { ko: '충신동', en: '충신동' },
          '11110168': { ko: '동숭동', en: '동숭동' },
          '11110169': { ko: '혜화동', en: '혜화동' },
          '11110170': { ko: '명륜1가', en: '명륜1가' },
          '11110171': { ko: '명륜2가', en: '명륜2가' },
          '11110172': { ko: '명륜4가', en: '명륜4가' },
          '11110173': { ko: '명륜3가', en: '명륜3가' },
          '11110174': { ko: '창신동', en: '창신동' },
          '11110175': { ko: '숭인동', en: '숭인동' },
          '11110176': { ko: '교남동', en: '교남동' },
          '11110177': { ko: '평동', en: '평동' },
          '11110178': { ko: '송월동', en: '송월동' },
          '11110179': { ko: '홍파동', en: '홍파동' },
          '11110180': { ko: '교북동', en: '교북동' },
          '11110181': { ko: '행촌동', en: '행촌동' },
          '11110182': { ko: '구기동', en: '구기동' },
          '11110183': { ko: '평창동', en: '평창동' },
          '11110184': { ko: '부암동', en: '부암동' },
          '11110185': { ko: '홍지동', en: '홍지동' },
          '11110186': { ko: '신영동', en: '신영동' },
          '11110187': { ko: '무악동', en: '무악동' },
        },
      },
      '11140000': {
        ko: '중구',
        en: '중구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11140101': { ko: '무교동', en: '무교동' },
          '11140102': { ko: '다동', en: '다동' },
          '11140103': { ko: '태평로1가', en: '태평로1가' },
          '11140104': { ko: '을지로1가', en: '을지로1가' },
          '11140105': { ko: '을지로2가', en: '을지로2가' },
          '11140106': { ko: '남대문로1가', en: '남대문로1가' },
          '11140107': { ko: '삼각동', en: '삼각동' },
          '11140108': { ko: '수하동', en: '수하동' },
          '11140109': { ko: '장교동', en: '장교동' },
          '11140110': { ko: '수표동', en: '수표동' },
          '11140111': { ko: '소공동', en: '소공동' },
          '11140112': { ko: '남창동', en: '남창동' },
          '11140113': { ko: '북창동', en: '북창동' },
          '11140114': { ko: '태평로2가', en: '태평로2가' },
          '11140115': { ko: '남대문로2가', en: '남대문로2가' },
          '11140116': { ko: '남대문로3가', en: '남대문로3가' },
          '11140117': { ko: '남대문로4가', en: '남대문로4가' },
          '11140118': { ko: '남대문로5가', en: '남대문로5가' },
          '11140119': { ko: '봉래동1가', en: '봉래동1가' },
          '11140120': { ko: '봉래동2가', en: '봉래동2가' },
          '11140121': { ko: '회현동1가', en: '회현동1가' },
          '11140122': { ko: '회현동2가', en: '회현동2가' },
          '11140123': { ko: '회현동3가', en: '회현동3가' },
          '11140124': { ko: '충무로1가', en: '충무로1가' },
          '11140125': { ko: '충무로2가', en: '충무로2가' },
          '11140126': { ko: '명동1가', en: '명동1가' },
          '11140127': { ko: '명동2가', en: '명동2가' },
          '11140128': { ko: '남산동1가', en: '남산동1가' },
          '11140129': { ko: '남산동2가', en: '남산동2가' },
          '11140130': { ko: '남산동3가', en: '남산동3가' },
          '11140131': { ko: '저동1가', en: '저동1가' },
          '11140132': { ko: '충무로4가', en: '충무로4가' },
          '11140133': { ko: '충무로5가', en: '충무로5가' },
          '11140134': { ko: '인현동2가', en: '인현동2가' },
          '11140135': { ko: '예관동', en: '예관동' },
          '11140136': { ko: '묵정동', en: '묵정동' },
          '11140137': { ko: '필동1가', en: '필동1가' },
          '11140138': { ko: '필동2가', en: '필동2가' },
          '11140139': { ko: '필동3가', en: '필동3가' },
          '11140140': { ko: '남학동', en: '남학동' },
          '11140141': { ko: '주자동', en: '주자동' },
          '11140142': { ko: '예장동', en: '예장동' },
          '11140143': { ko: '장충동1가', en: '장충동1가' },
          '11140144': { ko: '장충동2가', en: '장충동2가' },
          '11140145': { ko: '광희동1가', en: '광희동1가' },
          '11140146': { ko: '광희동2가', en: '광희동2가' },
          '11140147': { ko: '쌍림동', en: '쌍림동' },
          '11140148': { ko: '을지로6가', en: '을지로6가' },
          '11140149': { ko: '을지로7가', en: '을지로7가' },
          '11140150': { ko: '을지로4가', en: '을지로4가' },
          '11140151': { ko: '을지로5가', en: '을지로5가' },
          '11140152': { ko: '주교동', en: '주교동' },
          '11140153': { ko: '방산동', en: '방산동' },
          '11140154': { ko: '오장동', en: '오장동' },
          '11140155': { ko: '을지로3가', en: '을지로3가' },
          '11140156': { ko: '입정동', en: '입정동' },
          '11140157': { ko: '산림동', en: '산림동' },
          '11140158': { ko: '충무로3가', en: '충무로3가' },
          '11140159': { ko: '초동', en: '초동' },
          '11140160': { ko: '인현동1가', en: '인현동1가' },
          '11140161': { ko: '저동2가', en: '저동2가' },
          '11140162': { ko: '신당동', en: '신당동' },
          '11140163': { ko: '흥인동', en: '흥인동' },
          '11140164': { ko: '무학동', en: '무학동' },
          '11140165': { ko: '황학동', en: '황학동' },
          '11140166': { ko: '서소문동', en: '서소문동' },
          '11140167': { ko: '정동', en: '정동' },
          '11140168': { ko: '순화동', en: '순화동' },
          '11140169': { ko: '의주로1가', en: '의주로1가' },
          '11140170': { ko: '충정로1가', en: '충정로1가' },
          '11140171': { ko: '중림동', en: '중림동' },
          '11140172': { ko: '의주로2가', en: '의주로2가' },
          '11140173': { ko: '만리동1가', en: '만리동1가' },
          '11140174': { ko: '만리동2가', en: '만리동2가' },
        },
      },
      '11170000': {
        ko: '용산구',
        en: '용산구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11170101': { ko: '후암동', en: '후암동' },
          '11170102': { ko: '용산동2가', en: '용산동2가' },
          '11170103': { ko: '용산동4가', en: '용산동4가' },
          '11170104': { ko: '갈월동', en: '갈월동' },
          '11170105': { ko: '남영동', en: '남영동' },
          '11170106': { ko: '용산동1가', en: '용산동1가' },
          '11170107': { ko: '동자동', en: '동자동' },
          '11170108': { ko: '서계동', en: '서계동' },
          '11170109': { ko: '청파동1가', en: '청파동1가' },
          '11170110': { ko: '청파동2가', en: '청파동2가' },
          '11170111': { ko: '청파동3가', en: '청파동3가' },
          '11170112': { ko: '원효로1가', en: '원효로1가' },
          '11170113': { ko: '원효로2가', en: '원효로2가' },
          '11170114': { ko: '신창동', en: '신창동' },
          '11170115': { ko: '산천동', en: '산천동' },
          '11170116': { ko: '청암동', en: '청암동' },
          '11170117': { ko: '원효로3가', en: '원효로3가' },
          '11170118': { ko: '원효로4가', en: '원효로4가' },
          '11170119': { ko: '효창동', en: '효창동' },
          '11170120': { ko: '도원동', en: '도원동' },
          '11170121': { ko: '용문동', en: '용문동' },
          '11170122': { ko: '문배동', en: '문배동' },
          '11170123': { ko: '신계동', en: '신계동' },
          '11170124': { ko: '한강로1가', en: '한강로1가' },
          '11170125': { ko: '한강로2가', en: '한강로2가' },
          '11170126': { ko: '용산동3가', en: '용산동3가' },
          '11170127': { ko: '용산동5가', en: '용산동5가' },
          '11170128': { ko: '한강로3가', en: '한강로3가' },
          '11170129': { ko: '이촌동', en: '이촌동' },
          '11170130': { ko: '이태원동', en: '이태원동' },
          '11170131': { ko: '한남동', en: '한남동' },
          '11170132': { ko: '동빙고동', en: '동빙고동' },
          '11170133': { ko: '서빙고동', en: '서빙고동' },
          '11170134': { ko: '주성동', en: '주성동' },
          '11170135': { ko: '용산동6가', en: '용산동6가' },
          '11170136': { ko: '보광동', en: '보광동' },
        },
      },
      '11200000': {
        ko: '성동구',
        en: '성동구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11200101': { ko: '상왕십리동', en: '상왕십리동' },
          '11200102': { ko: '하왕십리동', en: '하왕십리동' },
          '11200103': { ko: '홍익동', en: '홍익동' },
          '11200104': { ko: '도선동', en: '도선동' },
          '11200105': { ko: '마장동', en: '마장동' },
          '11200106': { ko: '사근동', en: '사근동' },
          '11200107': { ko: '행당동', en: '행당동' },
          '11200108': { ko: '응봉동', en: '응봉동' },
          '11200109': { ko: '금호동1가', en: '금호동1가' },
          '11200110': { ko: '금호동2가', en: '금호동2가' },
          '11200111': { ko: '금호동3가', en: '금호동3가' },
          '11200112': { ko: '금호동4가', en: '금호동4가' },
          '11200113': { ko: '옥수동', en: '옥수동' },
          '11200114': { ko: '성수동1가', en: '성수동1가' },
          '11200115': { ko: '성수동2가', en: '성수동2가' },
          '11200118': { ko: '송정동', en: '송정동' },
          '11200122': { ko: '용답동', en: '용답동' },
        },
      },
      '11215000': {
        ko: '광진구',
        en: '광진구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11215101': { ko: '중곡동', en: '중곡동' },
          '11215102': { ko: '능동', en: '능동' },
          '11215103': { ko: '구의동', en: '구의동' },
          '11215104': { ko: '광장동', en: '광장동' },
          '11215105': { ko: '자양동', en: '자양동' },
          '11215107': { ko: '화양동', en: '화양동' },
          '11215109': { ko: '군자동', en: '군자동' },
        },
      },
      '11230000': {
        ko: '동대문구',
        en: '동대문구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11230101': { ko: '신설동', en: '신설동' },
          '11230102': { ko: '용두동', en: '용두동' },
          '11230103': { ko: '제기동', en: '제기동' },
          '11230104': { ko: '전농동', en: '전농동' },
          '11230105': { ko: '답십리동', en: '답십리동' },
          '11230106': { ko: '장안동', en: '장안동' },
          '11230107': { ko: '청량리동', en: '청량리동' },
          '11230108': { ko: '회기동', en: '회기동' },
          '11230109': { ko: '휘경동', en: '휘경동' },
          '11230110': { ko: '이문동', en: '이문동' },
        },
      },
      '11260000': {
        ko: '중랑구',
        en: '중랑구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11260101': { ko: '면목동', en: '면목동' },
          '11260102': { ko: '상봉동', en: '상봉동' },
          '11260103': { ko: '중화동', en: '중화동' },
          '11260104': { ko: '묵동', en: '묵동' },
          '11260105': { ko: '망우동', en: '망우동' },
          '11260106': { ko: '신내동', en: '신내동' },
        },
      },
      '11290000': {
        ko: '성북구',
        en: '성북구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11290101': { ko: '성북동', en: '성북동' },
          '11290102': { ko: '성북동1가', en: '성북동1가' },
          '11290103': { ko: '돈암동', en: '돈암동' },
          '11290104': { ko: '동소문동1가', en: '동소문동1가' },
          '11290105': { ko: '동소문동2가', en: '동소문동2가' },
          '11290106': { ko: '동소문동3가', en: '동소문동3가' },
          '11290107': { ko: '동소문동4가', en: '동소문동4가' },
          '11290108': { ko: '동소문동5가', en: '동소문동5가' },
          '11290109': { ko: '동소문동6가', en: '동소문동6가' },
          '11290110': { ko: '동소문동7가', en: '동소문동7가' },
          '11290111': { ko: '삼선동1가', en: '삼선동1가' },
          '11290112': { ko: '삼선동2가', en: '삼선동2가' },
          '11290113': { ko: '삼선동3가', en: '삼선동3가' },
          '11290114': { ko: '삼선동4가', en: '삼선동4가' },
          '11290115': { ko: '삼선동5가', en: '삼선동5가' },
          '11290116': { ko: '동선동1가', en: '동선동1가' },
          '11290117': { ko: '동선동2가', en: '동선동2가' },
          '11290118': { ko: '동선동3가', en: '동선동3가' },
          '11290119': { ko: '동선동4가', en: '동선동4가' },
          '11290120': { ko: '동선동5가', en: '동선동5가' },
          '11290121': { ko: '안암동1가', en: '안암동1가' },
          '11290122': { ko: '안암동2가', en: '안암동2가' },
          '11290123': { ko: '안암동3가', en: '안암동3가' },
          '11290124': { ko: '안암동4가', en: '안암동4가' },
          '11290125': { ko: '안암동5가', en: '안암동5가' },
          '11290126': { ko: '보문동4가', en: '보문동4가' },
          '11290127': { ko: '보문동5가', en: '보문동5가' },
          '11290128': { ko: '보문동6가', en: '보문동6가' },
          '11290129': { ko: '보문동7가', en: '보문동7가' },
          '11290130': { ko: '보문동1가', en: '보문동1가' },
          '11290131': { ko: '보문동2가', en: '보문동2가' },
          '11290132': { ko: '보문동3가', en: '보문동3가' },
          '11290133': { ko: '정릉동', en: '정릉동' },
          '11290134': { ko: '길음동', en: '길음동' },
          '11290135': { ko: '종암동', en: '종암동' },
          '11290136': { ko: '하월곡동', en: '하월곡동' },
          '11290137': { ko: '상월곡동', en: '상월곡동' },
          '11290138': { ko: '장위동', en: '장위동' },
          '11290139': { ko: '석관동', en: '석관동' },
        },
      },
      '11305000': {
        ko: '강북구',
        en: '강북구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11305101': { ko: '미아동', en: '미아동' },
          '11305102': { ko: '번동', en: '번동' },
          '11305103': { ko: '수유동', en: '수유동' },
          '11305104': { ko: '우이동', en: '우이동' },
        },
      },
      '11320000': {
        ko: '도봉구',
        en: '도봉구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11320105': { ko: '쌍문동', en: '쌍문동' },
          '11320106': { ko: '방학동', en: '방학동' },
          '11320107': { ko: '창동', en: '창동' },
          '11320108': { ko: '도봉동', en: '도봉동' },
        },
      },
      '11350000': {
        ko: '노원구',
        en: '노원구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11350102': { ko: '월계동', en: '월계동' },
          '11350103': { ko: '공릉동', en: '공릉동' },
          '11350104': { ko: '하계동', en: '하계동' },
          '11350105': { ko: '상계동', en: '상계동' },
          '11350106': { ko: '중계동', en: '중계동' },
        },
      },
      '11380000': {
        ko: '은평구',
        en: '은평구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11380101': { ko: '수색동', en: '수색동' },
          '11380102': { ko: '녹번동', en: '녹번동' },
          '11380103': { ko: '불광동', en: '불광동' },
          '11380104': { ko: '갈현동', en: '갈현동' },
          '11380105': { ko: '구산동', en: '구산동' },
          '11380106': { ko: '대조동', en: '대조동' },
          '11380107': { ko: '응암동', en: '응암동' },
          '11380108': { ko: '역촌동', en: '역촌동' },
          '11380109': { ko: '신사동', en: '신사동' },
          '11380110': { ko: '증산동', en: '증산동' },
          '11380114': { ko: '진관동', en: '진관동' },
        },
      },
      '11410000': {
        ko: '서대문구',
        en: '서대문구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11410101': { ko: '충정로2가', en: '충정로2가' },
          '11410102': { ko: '충정로3가', en: '충정로3가' },
          '11410103': { ko: '합동', en: '합동' },
          '11410104': { ko: '미근동', en: '미근동' },
          '11410105': { ko: '냉천동', en: '냉천동' },
          '11410106': { ko: '천연동', en: '천연동' },
          '11410107': { ko: '옥천동', en: '옥천동' },
          '11410108': { ko: '영천동', en: '영천동' },
          '11410109': { ko: '현저동', en: '현저동' },
          '11410110': { ko: '북아현동', en: '북아현동' },
          '11410111': { ko: '홍제동', en: '홍제동' },
          '11410112': { ko: '대현동', en: '대현동' },
          '11410113': { ko: '대신동', en: '대신동' },
          '11410114': { ko: '신촌동', en: '신촌동' },
          '11410115': { ko: '봉원동', en: '봉원동' },
          '11410116': { ko: '창천동', en: '창천동' },
          '11410117': { ko: '연희동', en: '연희동' },
          '11410118': { ko: '홍은동', en: '홍은동' },
          '11410119': { ko: '북가좌동', en: '북가좌동' },
          '11410120': { ko: '남가좌동', en: '남가좌동' },
        },
      },
      '11440000': {
        ko: '마포구',
        en: '마포구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11440101': { ko: '아현동', en: '아현동' },
          '11440102': { ko: '공덕동', en: '공덕동' },
          '11440103': { ko: '신공덕동', en: '신공덕동' },
          '11440104': { ko: '도화동', en: '도화동' },
          '11440105': { ko: '용강동', en: '용강동' },
          '11440106': { ko: '토정동', en: '토정동' },
          '11440107': { ko: '마포동', en: '마포동' },
          '11440108': { ko: '대흥동', en: '대흥동' },
          '11440109': { ko: '염리동', en: '염리동' },
          '11440110': { ko: '노고산동', en: '노고산동' },
          '11440111': { ko: '신수동', en: '신수동' },
          '11440112': { ko: '현석동', en: '현석동' },
          '11440113': { ko: '구수동', en: '구수동' },
          '11440114': { ko: '창전동', en: '창전동' },
          '11440115': { ko: '상수동', en: '상수동' },
          '11440116': { ko: '하중동', en: '하중동' },
          '11440117': { ko: '신정동', en: '신정동' },
          '11440118': { ko: '당인동', en: '당인동' },
          '11440120': { ko: '서교동', en: '서교동' },
          '11440121': { ko: '동교동', en: '동교동' },
          '11440122': { ko: '합정동', en: '합정동' },
          '11440123': { ko: '망원동', en: '망원동' },
          '11440124': { ko: '연남동', en: '연남동' },
          '11440125': { ko: '성산동', en: '성산동' },
          '11440126': { ko: '중동', en: '중동' },
          '11440127': { ko: '상암동', en: '상암동' },
        },
      },
      '11470000': {
        ko: '양천구',
        en: '양천구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11470101': { ko: '신정동', en: '신정동' },
          '11470102': { ko: '목동', en: '목동' },
          '11470103': { ko: '신월동', en: '신월동' },
        },
      },
      '11500000': {
        ko: '강서구',
        en: '강서구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11500101': { ko: '염창동', en: '염창동' },
          '11500102': { ko: '등촌동', en: '등촌동' },
          '11500103': { ko: '화곡동', en: '화곡동' },
          '11500104': { ko: '가양동', en: '가양동' },
          '11500105': { ko: '마곡동', en: '마곡동' },
          '11500106': { ko: '내발산동', en: '내발산동' },
          '11500107': { ko: '외발산동', en: '외발산동' },
          '11500108': { ko: '공항동', en: '공항동' },
          '11500109': { ko: '방화동', en: '방화동' },
          '11500110': { ko: '개화동', en: '개화동' },
          '11500111': { ko: '과해동', en: '과해동' },
          '11500112': { ko: '오곡동', en: '오곡동' },
          '11500113': { ko: '오쇠동', en: '오쇠동' },
        },
      },
      '11530000': {
        ko: '구로구',
        en: '구로구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11530101': { ko: '신도림동', en: '신도림동' },
          '11530102': { ko: '구로동', en: '구로동' },
          '11530103': { ko: '가리봉동', en: '가리봉동' },
          '11530106': { ko: '고척동', en: '고척동' },
          '11530107': { ko: '개봉동', en: '개봉동' },
          '11530108': { ko: '오류동', en: '오류동' },
          '11530109': { ko: '궁동', en: '궁동' },
          '11530110': { ko: '온수동', en: '온수동' },
          '11530111': { ko: '천왕동', en: '천왕동' },
          '11530112': { ko: '항동', en: '항동' },
        },
      },
      '11545000': {
        ko: '금천구',
        en: '금천구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11545101': { ko: '가산동', en: '가산동' },
          '11545102': { ko: '독산동', en: '독산동' },
          '11545103': { ko: '시흥동', en: '시흥동' },
        },
      },
      '11560000': {
        ko: '영등포구',
        en: '영등포구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11560101': { ko: '영등포동', en: '영등포동' },
          '11560102': { ko: '영등포동1가', en: '영등포동1가' },
          '11560103': { ko: '영등포동2가', en: '영등포동2가' },
          '11560104': { ko: '영등포동3가', en: '영등포동3가' },
          '11560105': { ko: '영등포동4가', en: '영등포동4가' },
          '11560106': { ko: '영등포동5가', en: '영등포동5가' },
          '11560107': { ko: '영등포동6가', en: '영등포동6가' },
          '11560108': { ko: '영등포동7가', en: '영등포동7가' },
          '11560109': { ko: '영등포동8가', en: '영등포동8가' },
          '11560110': { ko: '여의도동', en: '여의도동' },
          '11560111': { ko: '당산동1가', en: '당산동1가' },
          '11560112': { ko: '당산동2가', en: '당산동2가' },
          '11560113': { ko: '당산동3가', en: '당산동3가' },
          '11560114': { ko: '당산동4가', en: '당산동4가' },
          '11560115': { ko: '당산동5가', en: '당산동5가' },
          '11560116': { ko: '당산동6가', en: '당산동6가' },
          '11560117': { ko: '당산동', en: '당산동' },
          '11560118': { ko: '도림동', en: '도림동' },
          '11560119': { ko: '문래동1가', en: '문래동1가' },
          '11560120': { ko: '문래동2가', en: '문래동2가' },
          '11560121': { ko: '문래동3가', en: '문래동3가' },
          '11560122': { ko: '문래동4가', en: '문래동4가' },
          '11560123': { ko: '문래동5가', en: '문래동5가' },
          '11560124': { ko: '문래동6가', en: '문래동6가' },
          '11560125': { ko: '양평동1가', en: '양평동1가' },
          '11560126': { ko: '양평동2가', en: '양평동2가' },
          '11560127': { ko: '양평동3가', en: '양평동3가' },
          '11560128': { ko: '양평동4가', en: '양평동4가' },
          '11560129': { ko: '양평동5가', en: '양평동5가' },
          '11560130': { ko: '양평동6가', en: '양평동6가' },
          '11560131': { ko: '양화동', en: '양화동' },
          '11560132': { ko: '신길동', en: '신길동' },
          '11560133': { ko: '대림동', en: '대림동' },
          '11560134': { ko: '양평동', en: '양평동' },
        },
      },
      '11590000': {
        ko: '동작구',
        en: '동작구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11590101': { ko: '노량진동', en: '노량진동' },
          '11590102': { ko: '상도동', en: '상도동' },
          '11590103': { ko: '상도1동', en: '상도1동' },
          '11590104': { ko: '본동', en: '본동' },
          '11590105': { ko: '흑석동', en: '흑석동' },
          '11590106': { ko: '동작동', en: '동작동' },
          '11590107': { ko: '사당동', en: '사당동' },
          '11590108': { ko: '대방동', en: '대방동' },
          '11590109': { ko: '신대방동', en: '신대방동' },
        },
      },
      '11620000': {
        ko: '관악구',
        en: '관악구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11620101': { ko: '봉천동', en: '봉천동' },
          '11620102': { ko: '신림동', en: '신림동' },
          '11620103': { ko: '남현동', en: '남현동' },
        },
      },
      '11650000': {
        ko: '서초구',
        en: '서초구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11650101': { ko: '방배동', en: '방배동' },
          '11650102': { ko: '양재동', en: '양재동' },
          '11650103': { ko: '우면동', en: '우면동' },
          '11650104': { ko: '원지동', en: '원지동' },
          '11650106': { ko: '잠원동', en: '잠원동' },
          '11650107': { ko: '반포동', en: '반포동' },
          '11650108': { ko: '서초동', en: '서초동' },
          '11650109': { ko: '내곡동', en: '내곡동' },
          '11650110': { ko: '염곡동', en: '염곡동' },
          '11650111': { ko: '신원동', en: '신원동' },
        },
      },
      '11680000': {
        ko: '강남구',
        en: '강남구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11680101': { ko: '역삼동', en: '역삼동' },
          '11680103': { ko: '개포동', en: '개포동' },
          '11680104': { ko: '청담동', en: '청담동' },
          '11680105': { ko: '삼성동', en: '삼성동' },
          '11680106': { ko: '대치동', en: '대치동' },
          '11680107': { ko: '신사동', en: '신사동' },
          '11680108': { ko: '논현동', en: '논현동' },
          '11680110': { ko: '압구정동', en: '압구정동' },
          '11680111': { ko: '세곡동', en: '세곡동' },
          '11680112': { ko: '자곡동', en: '자곡동' },
          '11680113': { ko: '율현동', en: '율현동' },
          '11680114': { ko: '일원동', en: '일원동' },
          '11680115': { ko: '수서동', en: '수서동' },
          '11680118': { ko: '도곡동', en: '도곡동' },
        },
      },
      '11710000': {
        ko: '송파구',
        en: '송파구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11710101': { ko: '잠실동', en: '잠실동' },
          '11710102': { ko: '신천동', en: '신천동' },
          '11710103': { ko: '풍납동', en: '풍납동' },
          '11710104': { ko: '송파동', en: '송파동' },
          '11710105': { ko: '석촌동', en: '석촌동' },
          '11710106': { ko: '삼전동', en: '삼전동' },
          '11710107': { ko: '가락동', en: '가락동' },
          '11710108': { ko: '문정동', en: '문정동' },
          '11710109': { ko: '장지동', en: '장지동' },
          '11710111': { ko: '방이동', en: '방이동' },
          '11710112': { ko: '오금동', en: '오금동' },
          '11710113': { ko: '거여동', en: '거여동' },
          '11710114': { ko: '마천동', en: '마천동' },
        },
      },
      '11740000': {
        ko: '강동구',
        en: '강동구',
        children: {
          '0': { ko: '전체', en: 'all' },
          '11740101': { ko: '명일동', en: '명일동' },
          '11740102': { ko: '고덕동', en: '고덕동' },
          '11740103': { ko: '상일동', en: '상일동' },
          '11740105': { ko: '길동', en: '길동' },
          '11740106': { ko: '둔촌동', en: '둔촌동' },
          '11740107': { ko: '암사동', en: '암사동' },
          '11740108': { ko: '성내동', en: '성내동' },
          '11740109': { ko: '천호동', en: '천호동' },
          '11740110': { ko: '강일동', en: '강일동' },
        },
      },
    },
  },
} satisfies TLocationDataType;
