export const headerData = [
  {
    name: 'Carbon Budget',
    link: '/map',
  },
  {
    name: 'Dashboard',
    link: '/dashboard',
  },
  {
    name: 'About',
    link: '/about',
  },
  {
    name: 'Approach',
    link: '/approach',
  },
  {
    name: 'Monitoring Network',
    link: '/network',
  },
  {
    name: 'Team',
    link: '/team',
  },
];
