import { teamTitle } from '@static/teamData';
import styled from 'styled-components';
import OrganizationList from './OrganizationList';
import MemberList from './MemberList';

const Team = () => {
  return (
    <Container>
      <TitleContainer>
        <span>{teamTitle.bold}</span>
        {teamTitle.title}
      </TitleContainer>
      <ContentsContainer>
        <ContentTitle>{teamTitle.text}</ContentTitle>
        <MemberList />
        <OrganizationList />
      </ContentsContainer>
    </Container>
  );
};

export default Team;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 1196px;
  padding-bottom: 60px;
`;

const TitleContainer = styled.div`
  width: 100%;
  max-width: 1248px;
  color: ${({ theme }) => theme.newColors.white};
  word-break: keep-all;

  text-align: center;
  font-size: 35px;
  font-variation-settings: 'wght' 700;
  line-height: 70px; /* 200% */
  letter-spacing: -0.7px;

  margin: 200px 0px 195px 0px;

  span {
    color: ${({ theme }) => theme.newColors.primary};
  }
`;

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 1248px;
  padding-bottom: 100px;
`;

const ContentTitle = styled.div`
  color: ${({ theme }) => theme.newColors.white};
  font-size: 35px;
  font-variation-settings: 'wght' 800;
  line-height: 50px; /* 142.857% */
  letter-spacing: -0.7px;
`;
