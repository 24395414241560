export const apporachData = {
  main: {
    bold: 'Korea Carbon Project',
    text: '는 가용한 모든 자료를 활용하고 온실가스 산정에 검증된 과학적 방법을 활용하여 상세하고 정확한 온실가스 공간지도를 제공합니다!',
  },
  first: {
    title: 'Q1. 관측기반 온실가스 공간지도란?',
    text: '국가 온실가스 인벤토리 산정을 위해 사용하는 배출계수, 활동자료 및 최신의 관측자료를 이용하여 상세한 시공간 정보를 가진 탄소 정보 지도입니다.',
  },
  second: {
    title: 'Q2. 어떻게 1km 단위로 계산할 수 있나요?',
    text: '국가 온실가스 인벤토리 산정을 위해 이용되는 부문별 원 단위 활동자료를 수집하고, 시공간 상세 원 단위 활동자료가 없을 경우 추정치(proxy)를 이용하여 시공간 상세 활동자료를 생산하고, 시공간 상세 탄소 정보의 정확도는 관측자료를 통해 검증합니다.',
  },
  third: {
    title: 'Q3. 정책에 믿고 받영할 수 있나요?',
    text: '국가 온실가스 인벤토리 산정 수준에 부합할 뿐만 아니라 국제사회가 요구하는 새로운 검증 방법인 ’관측기반 검증’을 수행하기에 정책에 믿고 반영할 수 있습니다.',
  },
};

export const categoryData = {
  first: [
    {
      title: '활동자료',
      data: [
        {
          img: 'activity-1.svg',
          text: '에너지',
        },
        {
          img: 'activity-2.svg',
          text: '건물',
        },
        {
          img: 'activity-3.svg',
          text: '수송',
        },
        {
          img: 'activity-4.svg',
          text: '폐기물',
        },
        {
          img: 'activity-5.svg',
          text: '식생',
        },
        {
          img: 'activity-6.svg',
          text: '토양',
        },
      ],
      gridColumn: 3,
    },
    {
      title: '지상관측',
      data: [
        {
          img: 'ground-1.svg',
          text: '모바일',
        },
        {
          img: 'ground-2.svg',
          text: '타워',
        },
        {
          img: 'ground-3.svg',
          text: '지상고정',
        },
        {
          img: 'ground-4.svg',
          text: '필드',
        },
      ],
      gridColumn: 3,
    },
    {
      title: '항공 및 위성 관측',
      data: [
        {
          img: 'air-1.svg',
          text: '항공',
        },
        {
          img: 'air-2.svg',
          text: '위성',
        },
      ],
      gridColumn: 2,
    },
  ],
  second: {
    title: '융복합 분석',
    data: [
      {
        img: 'complex-1.svg',
        text: '배출계수',
      },
      {
        img: 'complex-2.svg',
        text: '해상도 전환',
      },
      {
        img: 'complex-3.svg',
        text: '머신러닝',
      },
      {
        img: 'complex-4.svg',
        text: '빅데이터',
      },
      {
        img: 'complex-5.svg',
        text: '대기모형',
      },
    ],
    gridColumn: 5,
  },
};
