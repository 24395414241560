import ConfirmButton from '@components/common/ConfirmButton';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronIcon } from '@assets/icons/chevron_left_icon.svg';
import DateSelector from '@components/common/selector/dateSelector/DateSelector';
import hexToRGBA from '@utils/hexToRGBA';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import LocationSelector from '@components/common/selector/locationSelector/LocationSelector';
import TenseSelector from '@components/common/selector/TenseSelector';
import CarbonCategorySelector from '@components/common/selector/carbonCategorySelector/CarbonCategorySelector';
import useMapStore, { CarbonBudgetDataType } from 'stores/mapStore';
import { TCarbonSourceDataType } from 'interfaces/mapInterface';
import { convertArrayToLocation, convertLocationToArray } from '@utils/convertLocationToArray';
import dayjs from 'dayjs';

export type TConditionInput = {
  startDate: string;
  endDate: string;
  location: string[];
  tense: string;
  carbonCategory: TCarbonSourceDataType[];
};

const ConditionPanel = () => {
  const { selectDataCondition, setDataCondition } = useMapStore();
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const defaultValues: TConditionInput = {
    startDate: selectDataCondition.startDate.format('YYYY-MM'),
    endDate: selectDataCondition.endDate.format('YYYY-MM'),
    location: convertLocationToArray(selectDataCondition.location),
    tense: selectDataCondition.tense,
    carbonCategory: selectDataCondition.dataType,
  };

  const methods = useForm<TConditionInput>({ defaultValues, mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const handleFormSubmit: SubmitHandler<TConditionInput> = ({ startDate, endDate, location, tense, carbonCategory }) => {
    const dataCondition: CarbonBudgetDataType = {
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      location: convertArrayToLocation(location),
      tense,
      dataType: carbonCategory,
    };

    setDataCondition(dataCondition);
  };

  return (
    <Container $isPanelOpen={isPanelOpen}>
      <FormProvider {...methods}>
        <PanelForm onSubmit={handleSubmit(handleFormSubmit)}>
          <SelectorContainer>
            <DateSelector selectorId="map-date-selector" startDateInputName="startDate" endDateInputName="endDate" width="250px" />
            <LocationSelector selectorId="map-location-selector" inputName="location" width="605px" />
            <TenseSelector selectorId="map-tense-selector" inputName="tense" width="274px" />
            <CarbonCategorySelector selectorId="map-carbon-category-selector" inputName="carbonCategory" width="274px" />
          </SelectorContainer>
          <ButtonContainer>
            <ConfirmButton text="검색하기" width="150px" disabled={!isValid} />
            <PanelCloseButton onClick={() => setIsPanelOpen(false)}>
              <ChevronIcon />
            </PanelCloseButton>
          </ButtonContainer>
        </PanelForm>
      </FormProvider>
      <PanelOpenButton onClick={() => setIsPanelOpen(true)}>
        <ChevronIcon />
      </PanelOpenButton>
    </Container>
  );
};

export default ConditionPanel;

const Container = styled.div<{ $isPanelOpen: boolean }>`
  display: flex;
  width: calc(100% + 20px);
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translateX(${({ $isPanelOpen }) => ($isPanelOpen ? 0 : 'calc(-100% + 20px)')});
  transition: transform 0.4s ease;
`;

const PanelForm = styled.form`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${({ theme }) => hexToRGBA(theme.newColors.dark1, 0.5)};
  box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.3);
  padding: 10px 5px 10px 20px;
`;

const SelectorContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const PanelCloseButton = styled.button`
  padding: 0 15px;
`;

const PanelOpenButton = styled.button`
  width: 20px;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.gray};
  border-radius: 0 5px 5px 0;

  svg {
    transform: rotate(180deg);
  }
`;
