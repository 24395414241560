import React from 'react';
import styled from 'styled-components';
import Calendar from '@components/common/selector/dateSelector/Calendar';
import { useController } from 'react-hook-form';
import useCloseWhenOutsideClick from '@hooks/useCloseWhenOutsideClick';
import dayjs from 'dayjs';

type TProps = {
  selectorId: string;
  // input name
  startDateInputName: string;
  endDateInputName: string;
  backgroundColor?: string;
  width?: string;
};

const DISPLAY_DATE_FORMAT = 'YYYY.MM';

// FormProvider로 감싸야 사용 가능
const DateSelector = ({ selectorId, startDateInputName, endDateInputName, backgroundColor, width = '100%' }: TProps) => {
  const {
    field: { name: startDateName, value: startDate, onChange: onStartDateChange },
  } = useController({ name: startDateInputName, rules: { required: true } });
  const {
    field: { name: endDateName, value: endDate, onChange: onEndDateChange },
  } = useController({ name: endDateInputName, rules: { required: true } });
  const [isCalendarOpen, setIsCalendarOpen] = useCloseWhenOutsideClick(`#${selectorId}`);

  const isDateSelected = startDate !== '' && endDate !== '';

  const handleModalToggle = () => {
    setIsCalendarOpen((prev) => !prev);
  };

  return (
    <Container id={selectorId}>
      <input name={startDateName} value={startDate} onChange={onStartDateChange} />
      <input name={endDateName} value={endDate} onChange={onEndDateChange} />
      <ViewerDiv $isDateSelected={isDateSelected} $backgroundColor={backgroundColor} $width={width} onClick={handleModalToggle}>
        {startDate === '' ? DISPLAY_DATE_FORMAT : dayjs(startDate).format(DISPLAY_DATE_FORMAT)} -{' '}
        {endDate === '' ? DISPLAY_DATE_FORMAT : dayjs(endDate).format(DISPLAY_DATE_FORMAT)}
      </ViewerDiv>
      {isCalendarOpen && (
        <CalendarContainer>
          <Calendar
            startDateInputName={startDateInputName}
            endDateInputName={endDateInputName}
            onCloseHandler={() => setIsCalendarOpen(false)}
            backgroundColor={backgroundColor}
          />
        </CalendarContainer>
      )}
    </Container>
  );
};

export default DateSelector;

const Container = styled.div`
  position: relative;
  z-index: 2;

  input {
    display: none;
  }
`;

const ViewerDiv = styled.div<{ $isDateSelected: boolean; $backgroundColor?: string; $width?: string }>`
  height: 40px;
  width: ${({ $width }) => $width};
  padding: 10px;
  border-radius: 5px;
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.newColors.dark1};

  color: ${({ theme, $isDateSelected }) => ($isDateSelected ? theme.newColors.white : theme.newColors.grey2)};
  font-size: 16px;
  font-variation-settings: 'wght' 400;
  line-height: 20px;
  letter-spacing: -0.32px;
  text-align: left;
  cursor: pointer;
`;

const CalendarContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 0px;
`;
