import { TCarbonSourceDataType } from 'interfaces/mapInterface';

export const DataTense = {
  present: { title: '현재', disabled: false },
  future: { title: '미래', disabled: true },
};

export const AbsorptionData = {
  nep: {
    key: 'nep',
    title: '생태계',
  },
} satisfies { [key: string]: { key: TCarbonSourceDataType; title: string } };

export const EmissionData = {
  ei: {
    key: 'ei',
    title: '에너지 산업',
  },
  mic: {
    key: 'mic',
    title: '제조업 및 건설업',
  },
  f: {
    key: 'f',
    title: '탈루',
  },
  rt: {
    key: 'rt',
    title: '수송',
  },
  os: {
    key: 'os',
    title: '기타',
  },
} satisfies { [key: string]: { key: TCarbonSourceDataType; title: string } };

export const carbonCategoryKey = {
  absorption: Object.keys(AbsorptionData),
  emission: Object.keys(EmissionData),
};

export const CarbonLegend = {
  mixed: {
    ko: '순탄소 배출량',
    color: ['#FFF3E0', '#FFE0B2', '#FFCC80', '#FFB74D', '#FFA726', '#FF9800', '#FB8C00', '#F57C00', '#EF6C00', '#E65100'],
  },
  absorption: {
    ko: '탄소 흡수량',
    color: ['#E0F2F1', '#B2DFDB', '#80CBC4', '#4DB6AC', '#26A69A', '#009688', '#00897B', '#00796B', '#00695C', '#004D40'],
  },
  emission: {
    ko: '탄소 배출량',
    color: ['#FFEBEE', '#FFCDD2', '#EF9A9A', '#E57373', '#EF5350', '#F44336', '#E53935', '#D32F2F', '#C62828', '#B71C1C'],
  },
};
