import CarbonCompareResult from '@components/dashboard/carbonCompare/CarbonCompareResult';
import CarbonCompareSearch from '@components/dashboard/carbonCompare/CarbonCompareSearch';
import useDashboardStore from 'stores/dashboardStore';
import styled from 'styled-components';

const CarbonCompare = () => {
  const { carbonCompare } = useDashboardStore();

  return (
    <Container>
      <Title>지역별 탄소 비교</Title>
      <Description>
        <span>관심있는 도시, 동네의 부문별 배출량과 추세변화를 비교할 수 있습니다.</span>
        <span className="gray">※ 수록기간 : 월, 2020.01 ~ 2022.12</span>
      </Description>
      <CarbonCompareSearch />
      {carbonCompare && <CarbonCompareResult param={carbonCompare} />}
    </Container>
  );
};

export default CarbonCompare;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Title = styled.h3`
  font-size: 25px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -0.5px;
`;

const Description = styled.p`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.36px;

  span.gray {
    color: ${({ theme }) => theme.newColors.grey1};
  }
`;
