import { getCarbonLegendBackground, getCarbonLegendType } from '@utils/carbonData';
import { TCarbonSourceDataType } from 'interfaces/mapInterface';
import React from 'react';
import styled from 'styled-components';

type Props = {
  carbonType: TCarbonSourceDataType[];
};

const Legend = ({ carbonType }: Props) => {
  const type = getCarbonLegendType(carbonType);

  return (
    <Container>
      <RangeBar $type={type} />
      <Value>
        <Text>Min</Text>
        <Text>tonCO2eq</Text>
        <Text>Max</Text>
      </Value>
    </Container>
  );
};

export default Legend;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const RangeBar = styled.div<{ $type: string }>`
  width: 100%;
  height: 20px;
  border-radius: 5px;
  background: ${(props) => getCarbonLegendBackground(props.$type)};
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.newColors.grey2};
  font-size: 14px;
  font-variation-settings: 'wght' 400;
  letter-spacing: -0.28px;
`;
