import { organizationsData } from '@static/teamData';
import styled from 'styled-components';
import OrganizationItem from './OrganizationItem';
import React from 'react';

const OrganizationList = () => {
  return (
    <Container>
      {organizationsData.map((data, idx) => (
        <React.Fragment key={data.title}>
          <OrganizationItem data={data} />
          {idx < organizationsData.length - 1 && <Hr />}
        </React.Fragment>
      ))}
    </Container>
  );
};

export default OrganizationList;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 1248px;
`;

const Hr = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  background-color: ${({ theme }) => theme.newColors.dark2};
`;
