import { styled } from 'styled-components';
import { ReactComponent as CloseIcon } from '@assets/icons/close_icon_19.svg';
import LanguageChangeButton from './LanguageChangeButton';
import { Link, useLocation } from 'react-router-dom';
import { headerData } from '@static/headerData';
import { colors } from '@styles/theme';

type Props = {
  handleMenuClose: () => void;
};

const MobileMenuModal = ({ handleMenuClose }: Props) => {
  const { pathname } = useLocation();

  const handleNavButtonClick = () => {
    handleMenuClose();
  };

  return (
    <Container>
      <Header>
        {/* <LanguageChangeButton /> */}
        <button onClick={handleMenuClose}>
          <CloseIcon fill={colors.text} />
        </button>
      </Header>
      <NavBar>
        {headerData.map((route) => (
          <Link key={route.name} to={route.link} className={pathname === route.link ? 'active' : ''}>
            <NavButton onClick={handleNavButtonClick}>{route.name}</NavButton>
          </Link>
        ))}
      </NavBar>
    </Container>
  );
};

export default MobileMenuModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bg};
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.colors.bg2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.headerBorder};

  button {
    display: flex;
    align-items: center;
  }
`;

const NavBar = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;

  a.active {
    color: ${({ theme }) => theme.colors.orange};
  }
`;

const NavButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.headerBorder};
  font-size: 16px;
  font-variation-settings: 'wght' 500;
  letter-spacing: -0.32px;
`;
