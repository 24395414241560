import { getLocationName } from '@utils/getLocationName';
import hexToRGBA from '@utils/hexToRGBA';
import { useTopRankCarbonQuery } from 'queries/useTopRankCarbonQuery';
import { useEffect, useState } from 'react';
import useDashboardStore from 'stores/dashboardStore';
import styled from 'styled-components';

const TABLE_HEADER = ['지역명', '탄소 수치'];

type TCarbonRankingData = {
  location: string;
  carbonAmount: number;
  cityRatio?: number;
  nationalRatio?: number;
};

const CarbonRankingResult = () => {
  const [headers, setHeaders] = useState<string[]>(TABLE_HEADER);
  const [formattedData, setFormattedData] = useState<TCarbonRankingData[]>();
  const { carbonRanking } = useDashboardStore();
  const { data } = useTopRankCarbonQuery({ dataType: carbonRanking.dataType, location: carbonRanking.location }, !!carbonRanking);

  useEffect(() => {
    if (!data) return;
    setHeaders(
      carbonRanking.location.sig_cd ? TABLE_HEADER.concat(['시•군•구 비율', '시도 비율']) : TABLE_HEADER.concat(['시도 비율', '전국 비율']),
    );
    setFormattedData(
      carbonRanking.location.sig_cd
        ? data.data.map((item) => ({
            location: getLocationName([carbonRanking.location.ctp_cd, carbonRanking.location.sig_cd, item.emd_cd], 2),
            carbonAmount: item.total,
            cityRatio: data.total.sig_cd ? (item.total / data.total.sig_cd) * 100 : undefined,
            nationalRatio: (item.total / data.total.ctp_cd) * 100,
          }))
        : data.data.map((item) => ({
            location: getLocationName([carbonRanking.location.ctp_cd, item.sig_cd], 1),
            carbonAmount: item.total,
            cityRatio: (item.total / data.total.ctp_cd) * 100,
            nationalRatio: (item.total / data.total.ctp_cd) * 100,
          })),
    );
  }, [data]);

  return (
    <Container>
      <TableContainer>
        {headers.map((header) => (
          <TableHeader key={header}>{header}</TableHeader>
        ))}
        {formattedData &&
          formattedData.map((item, idx) => (
            <TableRow key={item.location}>
              <TableCell>
                <span className="index">{String(idx + 1).padStart(2, '0')}</span>
                <span>{item.location}</span>
              </TableCell>
              <TableCell>
                <span>{item.carbonAmount.toLocaleString('ko-KR', { minimumFractionDigits: 2 })}</span>
                <span className="unit">tonCO2eq</span>
              </TableCell>
              <TableCell>{item.cityRatio ? item.cityRatio.toLocaleString('ko-KR') : '-'} %</TableCell>
              <TableCell>{item.nationalRatio ? item.nationalRatio.toLocaleString('ko-KR') : '-'} %</TableCell>
            </TableRow>
          ))}
      </TableContainer>
    </Container>
  );
};

export default CarbonRankingResult;

const Container = styled.div`
  width: 100%;
  min-width: fit-content;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark2, 0.5)};
  border-radius: 8px;
`;

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, auto) repeat(3, 200px);
  padding: 20px 0px;
`;

const TableHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.newColors.grey2};

  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => hexToRGBA(theme.newColors.grey1, 0.3)};

  &:nth-child(1) {
    margin-left: 20px;
  }

  &:nth-child(4) {
    margin-right: 20px;
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    text-align: right;
  }
`;

const TableCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  font-size: 16px;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;

  margin-top: 20px;

  span.index {
    margin-right: 20px;
  }

  span.unit {
    color: ${({ theme }) => theme.newColors.grey1};
    margin-left: 10px;
  }

  &:nth-child(1) {
    margin-left: 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.4px;

    justify-content: start;
  }

  &:nth-child(4) {
    margin-right: 20px;
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    text-align: right;
  }
`;

const TableRow = styled.div`
  display: contents;
`;
