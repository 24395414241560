export const aboutData = {
  main: {
    title:
      'Korea Carbon Project, KCP는 한국형 하이브리드 온실가스 인벤토리 산정 기술을 개발하는 프로젝트로, 이를 바탕으로 우리나라의 상세한 탄소 배출·흡수량 정보를 조회할 수 있는 공간정보지도 플랫폼을 제공합니다. 공간정보지도 플랫폼을 통해 국내 탄소 배출·흡수 현황 및 전망치를 누구나 손쉽게 파악할 수 있습니다.',
  },
  goal: {
    title: 'Our Goal',
    content:
      '플랫폼을 통해 누구나 손쉽게 탄소 현황 정보를 파악할 수 있도록 상세하고 정확한 탄소 배출량과 흡수량 정보를 제공합니다. 이를 통해 우리나라의 2050 Net-zero 달성을 지원합니다.',
    goal: {
      text: [
        {
          title: '01. 탄소 배출원',
          text: '탄소를 배출하는 주요 요소들은 에너지 산업, 농축 산업, 건물, 폐기물, 수송 등이 있습니다.',
        },
        {
          title: '02. 탄소 흡수원',
          text: '탄소를 흡수하는 주요 요소들은 산림, 토양 및 바이오차, 도시 공원 등이 있습니다.',
        },
        { title: '03. 국내 온실가스 부문별 배출량' },
        { title: '04. 국내 온실가스 종류별 배출량' },
        { title: '05. 한국형 하이브리드 인벤토리 및 공간정보지도 개발' },
        { title: '05. 한국형 하이브리드 인벤토리 및 공간정보지도 개발' },
      ],
      image: [
        { src: '/images/about/goal/goal1.png', bottom: 50 },
        { src: '/images/about/goal/goal2.png', bottom: 50 },
        { src: '/images/about/goal/goal3.png', bottom: 110 },
        { src: '/images/about/goal/goal4.png', bottom: 55 },
        { src: '/images/about/goal/goal5.png', bottom: 100 },
        { src: '/images/about/goal/goal6.png', bottom: 150 },
      ],
    },
  },
  purpose: {
    title: 'Our Purpose',
    content: [
      {
        text: '탄소중립을 달성하기 위해 모든 분야에서 탄소 배출과 흡수를 관리하는 것이 중요합니다. 특히, 이를 위해 탄소 배출과 흡수의 현황을 ',
      },
      { type: 'bold', text: '신속하게, 상세하게, 정확하게' },
      { text: ' 파악할 수 있는 고해상도의 시공간 인벤토리의 구축이 필수적입니다.' },
    ],
    purpose: [
      '현재 세계는 기후 위기에 대응하여 탄소중립 사회로의 전환을 추진하고 있습니다. EU는 탄소세를 도입하고, 여러 기업들은 RE100을 선언하여 탄소 감축을 목표로 하고 있습니다. 한국 역시 2050년까지의 탄소중립 비전을 선포하고, 효과적인 정책과 신기술을 도입하고 있지만, 이산화탄소 및 메탄의 배출과 흡수를 정확히 파악하는 데 기술적 한계가 있습니다.',
      '2022년에는 미국국립과학원이 제안한 하이브리드 인벤토리 방법을 포함한 새로운 산정 기술과 검증 방법론이 제안되었으며, 이는 주요 대도시에서 위성 자료와 국가 공인 데이터를 활용하여 온실가스 배출량을 정밀하게 산정하는 데 활용되고 있습니다.',
      '이를 기반으로 한국은 미래 탄소중립 시나리오에 따른 탄소 배출량 변화와 육상 탄소 흡수량을 예측할 수 있는 체계를 구축 중입니다. 이를 위해 개발 중인 온실가스 공간 정보지도 플랫폼을 통해 국가 및 지자체의 탄소중립 이행을 과학적으로 평가하고 진단할 계획입니다.',
    ],
  },
  roadmap: {
    title: 'Our Roadmap',
    content:
      '입체관측 기반의 온실가스 공간정보지도 플랫폼은 한국의 탄소 배출 및 흡수 현황과 전망을 제공합니다. 이 플랫폼은 국내외 연구 협의체와 협력하여 인공위성 관측 자료를 확보하고 데이터의 품질을 개선합니다. 서울시를 시작으로 주요 대도시와 전국에 걸쳐 온실가스 흡수량과 배출량을 정확하게 공시하여 한국이 기후 선도국으로 발돋움할 수 있도록 지원합니다.',
    roadmap: {
      map: [
        { title: '서울', src: '/images/about/roadmap/map1.png' },
        { title: '6개 광역시', src: '/images/about/roadmap/map2.png' },
        { title: '전국', src: '/images/about/roadmap/map3.png' },
      ],
      history: [
        { year: 2023, text1: '1차 년도', text2: '파일럿 시스템 구축' },
        { year: 2024, text1: '2차 년도', text2: '고해상도 방법론 개발' },
        { year: 2025, text1: '3차 년도', text2: '미래 탄소 예측 시범 적용' },
        { year: 2026, text1: '4차 년도', text2: '국내 최적화' },
        { year: 2027, text1: '5차 년도', text2: '인벤토리 고도화' },
      ],
      plan: [
        { title: '현재탄소수지', data: ['서울', '6개 광역시', '전국'] },
        {
          title: '미래탄소예측',
          data: ['파일럿 시스템 구축', '고해상도 방법론 개발', '미래 탄소 예측 시범 적용', '국내 최적화', '인벤토리 고도화'],
        },
      ],
    },
  },
};
