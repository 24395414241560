import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import MapCommonButton from '../common/MapCommonButton';
import { ReactComponent as InfoIcon } from '@assets/icons/info_icon.svg';
import { NavigationControl } from 'react-map-gl';

const ControlPanel = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClickHandler = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <>
      {/* TODO: info button 누르면 Modal 여기서 구현 */}
      <NavigationControl showCompass={false} position={'bottom-right'} />
      <ButtonContainer>
        <MapCommonButton
          icon={<InfoIcon />}
          onClick={onClickHandler}
          isHover={true}
          isSelected={true}
          defaultSelected={isOpen}
          bgColorType={'dark'}
        />
      </ButtonContainer>
    </>
  );
};

export default ControlPanel;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 130px;
  right: 20px;
`;
