import { CarbonLegend } from '@static/mapData';
import { TCarbonType } from 'interfaces/mapInterface';
import styled from 'styled-components';

const getFormattedNumber = (num: number) => {
  const threshold = 1e-3;

  if (num === 0) {
    return '0';
  } else if (Math.abs(num) < threshold) {
    return Math.abs(num).toExponential(3);
  } else {
    return Math.abs(num).toLocaleString('ko-KR');
  }
};

type TProps = {
  carbonAmount: number;
  carbonType: TCarbonType;
};

const LocationTotalCarbon = ({ carbonAmount, carbonType }: TProps) => {
  return (
    <Container>
      <span className="title">
        {CarbonLegend[carbonType].ko} {carbonType === 'absorption' && (carbonAmount > 0 ? '(흡수)' : '(배출)')}
      </span>
      <CarbonContent>
        <span className="number">{getFormattedNumber(carbonAmount)}</span>
        <span className="unit">tonCO2eq</span>
      </CarbonContent>
    </Container>
  );
};

export default LocationTotalCarbon;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  span.title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
  }
`;

const CarbonContent = styled.div`
  display: flex;
  justify-content: space-between;

  span.number {
    font-size: 25px;
    font-weight: 800;
    letter-spacing: -0.5px;
    color: ${({ theme }) => theme.newColors.primary};
  }

  span.unit {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.28px;
    color: ${({ theme }) => theme.newColors.grey2};
  }
`;
