import React, { useEffect, useState } from 'react';
import Mapbox from './Mapbox';
import CautionModal from './CautionModal';
import { useCookies } from 'react-cookie';

const Map = () => {
  const [hasCookie, setHasCookie] = useState<boolean>(false);
  const [appCookies] = useCookies();

  useEffect(() => {
    if (appCookies.carbonbudget) setHasCookie(true);
  }, []);

  return (
    <>
      {!hasCookie && <CautionModal />}
      <Mapbox />
    </>
  );
};

export default Map;
