import { useQuery } from '@tanstack/react-query';
import { getCarbonChartData } from 'api/dashboardApi';
import { Dayjs } from 'dayjs';
import { TCarbonSourceDataType, TLocationDataType } from 'interfaces/mapInterface';

type ParamsType = {
  startDate: Dayjs;
  endDate: Dayjs;
  tense: string;
  dataType: TCarbonSourceDataType[];
  location: TLocationDataType[];
};

export const useCarbonChartQuery = (condition: ParamsType) => {
  const { startDate, endDate, tense, dataType, location } = condition;
  const formatStartDate = startDate?.format('YYYYMM');
  const formatEndDate = endDate?.format('YYYYMM');

  return useQuery({
    queryKey: ['dashboard', 'chart', location, formatStartDate, formatEndDate, tense, dataType],
    queryFn: () =>
      getCarbonChartData({
        startDate: formatStartDate,
        endDate: formatEndDate,
        location,
        dataType,
      }),
    staleTime: Infinity,
    gcTime: Infinity,
  });
};
