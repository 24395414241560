import CarbonRankingResult from '@components/dashboard/carbonRanking/CarbonRankingResult';
import CarbonRankingSearchBar from '@components/dashboard/carbonRanking/CarbonRankingSearchBar';
import styled from 'styled-components';

const CarbonRanking = () => {
  return (
    <Container>
      <Title>지역별 탄소 랭킹</Title>
      <Description>가장 많이 탄소배출 혹은 흡수한 지역이 어디인지 1위-5위까지 순위를 확인할 수 있습니다.</Description>
      <CarbonRankingSearchBar />
      <CarbonRankingResult />
    </Container>
  );
};

export default CarbonRanking;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Title = styled.h3`
  font-size: 25px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -0.5px;
`;

const Description = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.36px;
`;
