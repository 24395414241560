import { networkLegendData } from '@static/networkData';
import hexToRGBA from '@utils/hexToRGBA';
import React from 'react';
import styled from 'styled-components';

type Props = {};

const LegendPanel = (props: Props) => {
  const data = networkLegendData;
  return (
    <Container>
      <Text className="title">관리기관</Text>
      {Object.keys(data).map((item) => (
        <Item key={data[item].title}>
          <Marker $color={data[item].color} />
          <Text>{data[item].title}</Text>
        </Item>
      ))}
    </Container>
  );
};

export default LegendPanel;

const Container = styled.div`
  display: flex;
  width: 200px;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  border-radius: 5px;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark1, 0.8)};
  box-shadow: 2px 5px 10px 0px ${({ theme }) => hexToRGBA(theme.newColors.black, 0.3)};
  backdrop-filter: blur(15px);

  position: absolute;
  bottom: 40px;
  left: 5px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Marker = styled.div<{ $color: string }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;

  background-color: ${({ $color }) => $color};
  border: 2px solid ${({ $color }) => hexToRGBA($color, 0.3)};
  background-clip: padding-box;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.newColors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;

  &.title {
    font-weight: 700;
  }
`;
