import styled from 'styled-components';

import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/css/navigation';

import SwiperItem from './SwiperItem';
import hexToRGBA from '@utils/hexToRGBA';
import { aboutData } from '@static/aboutData';
import { useState } from 'react';

const SwiperBox = () => {
  const data = aboutData.goal.goal;
  const [_, setInit] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleSwipe = (swiper: SwiperClass) => {
    setCurrentIndex(swiper.activeIndex);
  };

  return (
    <Container>
      <TextContainer>
        <div className="title">
          <span>KCP</span> 연구의 필요성
        </div>
        <div className="subtitle">{data.text[currentIndex].title}</div>
        {data.text[currentIndex].text && <div className="text">{data.text[currentIndex].text}</div>}
      </TextContainer>
      <Swiper
        direction="horizontal"
        navigation={true}
        modules={[Navigation]}
        onInit={() => setInit(true)}
        onActiveIndexChange={(swiper) => handleSwipe(swiper)}
        spaceBetween={10}
      >
        {data.image.map((el) => (
          <SwiperSlide key={el.src}>
            <SwiperItem imgSrc={el.src} bottom={el.bottom} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default SwiperBox;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 700px;
  overflow: hidden;

  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.newColors.dark2};
  background-color: ${({ theme }) => theme.newColors.dark1};

  .swiper {
    margin: 0;
  }

  .swiper-wrapper {
    margin: 0;
  }

  .swiper-slide {
    width: 100%;
  }

  div.swiper-button-prev,
  div.swiper-button-next {
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => hexToRGBA(theme.newColors.white, 0.8)};
    border-radius: 50%;

    &::after {
      font-size: 18px;
      font-variation-settings: 'wght' 800;
      color: ${({ theme }) => theme.newColors.grey1};
    }
  }
`;

const TextContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  div.title {
    color: ${({ theme }) => theme.newColors.white};
    font-size: 25px;
    font-weight: 600;
    line-height: 40px; /* 160% */
    letter-spacing: -0.5px;
    span {
      color: ${({ theme }) => theme.newColors.primary};
    }
  }

  div.subtitle {
    color: ${({ theme }) => theme.newColors.white};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px; /* 150% */
    letter-spacing: -0.4px;
  }

  div.text {
    color: ${({ theme }) => theme.newColors.white};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.32px;
  }
`;
