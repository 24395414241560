import hexToRGBA from '@utils/hexToRGBA';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '@assets/icons/close_icon_15.svg';
import LocationTotalCarbon from '@components/map/locationPanel/LocationTotalCarbon';
import { Position } from '@turf/helpers';
import { Popup } from 'react-map-gl';
import { TCarbonSourceDataType, TPopupCarbonDataType } from 'interfaces/mapInterface';
import { getCarbonLegendType } from '@utils/carbonData';
import LocationCarbonCategory from './LocationCarbonCategory';
import { getLocationName } from '@utils/getLocationName';

type TProps = {
  coordinate: Position;
  data: TPopupCarbonDataType;
  onClose: () => void;
};

const LocationPanel = ({ coordinate, data, onClose }: TProps) => {
  const { id, ctp_cd, emd_cd, sig_cd, total, ...rest } = data;

  return (
    <CustomPopup
      latitude={coordinate[1]}
      longitude={coordinate[0]}
      onClose={onClose}
      closeButton={false}
      closeOnClick={false}
      anchor="left"
      offset={20}
    >
      <Content>
        <Title>{getLocationName([ctp_cd, sig_cd, emd_cd], 2)}</Title>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <LocationTotalCarbon carbonAmount={data.total} carbonType={getCarbonLegendType(Object.keys(rest) as TCarbonSourceDataType[])} />
        <Hr />
        <LocationCarbonCategory categoryList={{ id, ...rest }} />
      </Content>
    </CustomPopup>
  );
};

export default LocationPanel;

const CustomPopup = styled(Popup)`
  .mapboxgl-popup-tip {
    border: none;
  }

  .mapboxgl-popup-content {
    background: transparent;
    border-radius: 5px;
    padding: 0px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 280px;
  padding: 15px;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  border-radius: 5px;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark3, 0.7)};
  box-shadow: 2px 5px 10px 0px ${({ theme }) => hexToRGBA(theme.newColors.black, 0.3)};
  backdrop-filter: blur(10px);
`;

const Title = styled.span`
  font-size: 18px;
  font-variation-settings: 'wght' 600;
  line-height: 22px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
`;

const Hr = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.grey1, 0.5)};
`;
