import { categoryData } from '@static/approachData';
import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '@assets/icons/category_arrow_icon.svg';

const Category = () => {
  return (
    <Container>
      <FlexContainer>
        {categoryData.first.map((category) => (
          <CategoryContainer key={category.title}>
            <span className="title">{category.title}</span>
            <CategoryContent $gridColumn={category.gridColumn}>
              {category.data.map((item) => (
                <IconItem key={item.text}>
                  <img src={`images/approach/category/${item.img}`} />
                  <span>{item.text}</span>
                </IconItem>
              ))}
            </CategoryContent>
          </CategoryContainer>
        ))}
      </FlexContainer>
      <ArrowIcon width={'100%'} />
      <CategoryContainer $isOrange={true}>
        <span className="title">{categoryData.second.title}</span>
        <CategoryContent $gridColumn={categoryData.second.gridColumn}>
          {categoryData.second.data.map((item) => (
            <IconItem key={item.text} className="col">
              <img src={`images/approach/category/${item.img}`} />
              <span>{item.text}</span>
            </IconItem>
          ))}
        </CategoryContent>
      </CategoryContainer>
    </Container>
  );
};

export default Category;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 100px auto auto auto;
  max-width: 1060px;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 50px;
  width: 100%;
`;

const CategoryContainer = styled.div<{ $isOrange?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  min-width: 320px;
  padding: 40px 30px;
  border-radius: 20px;
  background: ${({ theme, $isOrange }) => ($isOrange ? 'linear-gradient(135deg, #FFAB5E 0%, #FF8718 77.23%);' : theme.colors.mapPanelBg)};
  color: ${({ theme, $isOrange }) => ($isOrange ? theme.colors.white : theme.colors.memberText)};

  span.title {
    font-size: 25px;
    font-variation-settings: 'wght' 700;
    line-height: 30px;
    letter-spacing: -0.5px;
  }
`;

const CategoryContent = styled.div<{ $gridColumn: number }>`
  ${({ $gridColumn }) =>
    $gridColumn < 3
      ? `display: flex;
      justify-content: center;
      gap: 50px;`
      : $gridColumn === 5
      ? `display: flex;
      gap: 37px;
      `
      : `display: grid;
      grid-template-columns: repeat(3, 80px);
      justify-content: space-between;
      row-gap: 30px;
    `};
  width: 100%;
`;

const IconItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  justify-self: center;
  gap: 10px;
  width: 80px;
  height: 90px;
  font-size: 20px;
  font-variation-settings: 'wght' 600;
  letter-spacing: -0.4px;
  white-space: nowrap;
`;
