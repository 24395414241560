import { Dayjs } from 'dayjs';
import { TCarbonSourceDataType, TLocationDataType } from 'interfaces/mapInterface';
import { StateCreator, create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type TCarbonRanking = {
  location: TLocationDataType;
  tense: string;
  dataType: TCarbonSourceDataType[];
};

export type TCarbonCompare = {
  startDate: Dayjs;
  endDate: Dayjs;
  tense: string;
  dataType: TCarbonSourceDataType[];
  location: TLocationDataType[];
};

type TDashboardState = {
  carbonRanking: TCarbonRanking;
  carbonCompare?: TCarbonCompare;
};

type TDashboardAction = {
  setCarbonRanking: (data: TCarbonRanking) => void;
  setCarbonCompare: (data: TCarbonCompare) => void;
};

const store: StateCreator<TDashboardState & TDashboardAction> = (set) => ({
  carbonRanking: {
    location: {
      ctp_cd: 11000000,
      sig_cd: undefined,
    },
    tense: 'present',
    dataType: ['ei', 'f', 'mic', 'rt', 'os', 'nep'],
  },
  carbonCompare: undefined,
  setCarbonRanking: (data) => set({ carbonRanking: data }),
  setCarbonCompare: (data) => set({ carbonCompare: data }),
});

const useDashboardStore = process.env.REACT_APP_SERVER_MODE === 'development' ? create(devtools(immer(store))) : create(immer(store));

export default useDashboardStore;
