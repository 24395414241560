import { styled } from 'styled-components';
import Header from './header/Header';
import Footer from './Footer';
import { Outlet } from 'react-router';

const Layout = () => {
  return (
    <Container>
      <Header />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </Container>
  );
};

export default Layout;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const Main = styled.main`
  width: 100%;
  height: 100%;
  padding-top: 60px;
`;
