import { IMemberInfo } from '@components/team/MemberItem';
import { OrganizationsType } from '@components/team/OrganizationItem';

export const teamTitle = {
  bold: 'Korea Carbon Project',
  title:
    '는 국내 주요 연구 협의체가 참여하는 프로젝트로서, 국내에서는 처음으로 자세한 탄소 배출과 흡수 현황을 제공함으로써 국가와 지방 자치단체가 탄소 중립을 달성할 수 있도록 지원합니다.',
  text: 'KCP 참여 기관 및  팀원 소개',
};

export const profile: { id: number; title: string; member: IMemberInfo[] }[] = [
  {
    id: 1,
    title: '한국형 하이브리드 인벤토리 산정기술 개발',
    member: [
      {
        image: '정수종.jpg',
        info: [
          '총괄 PI',
          '정수종 교수',
          '서울대학교',
          '총괄을 맡은 서울대학교 정수종 교수팀은 국내에서 유일하게 지상, 모바일, 항공, 위성 등 다 매체 온실가스 관측에 대한 연구를 활발하게 진행하고 있으며 본 과제를 총괄합니다.',
        ],
      },
      {
        image: '곽경환.jpg',
        info: [
          '참여연구원',
          '곽경환 교수',
          '강원대학교',
          '강원대학교 곽경환 교수팀은 지상, 모바일, 드론 등 다양한 규모의 대기환경 모니터링 연구를 수행 중이며 본 연구에서 입체관측을 담당합니다.',
        ],
      },
      {
        image: '박두선.jpg',
        info: [
          '참여연구원',
          '박두선 교수',
          '경북대학교',
          '경북대학교 박두선 교수팀은 지역 기후변화 및 기후리스크 분석을 전문적으로 연구하며 본 연구에서 북한의 기후변화 및 탄소수지에 대한 파일럿 연구를 수행합니다.',
        ],
      },
      {
        image: '손석우.jpg',
        info: [
          '참여연구원',
          '손석우 교수',
          '서울대학교',
          '서울대학교 손석우 교수팀은 탄소순환 모델링의 핵심 파트인 대기모델링 분야의 연구를 진행합니다.',
        ],
      },
      {
        image: '국종성.jpg',
        info: [
          '참여연구원',
          '국종성 교수',
          '서울대학교',
          '서울대학교 국종성 교수는 전지구 규모 기후변화 및 탄소순환 모델링을 전문적으로 하며 본 연구에서 탄소농도 지도 표출에 대한 연구를 수행합니다.',
        ],
      },
      {
        image: '이봉재.jpg',
        info: [
          '참여연구원',
          '이봉재 박사',
          'KTR',
          'KTR 이봉재 박사팀은 온실가스 공정시험기준 및 인증을 전문으로 하며 사업장 규모에서의 배출량 산정 및 검증에 대한 연구를 진행합니다.',
        ],
      },
      {
        image: '김민식.jpg',
        info: [
          '참여연구원',
          '김민식 본부장',
          '나라스페이스',
          '나라스페이스 김민식 본부장팀은 다양한 정표 표출 서비스 경력이 있으며 본 연구에서 국가 온실가스 공간정보지도를 표출하는 웹기반 플랫폼을 구축합니다.',
        ],
      },
    ],
  },
  {
    id: 2,
    title: '시공간 상세격자형 탄소 배출량 산정 및 검증 기술 개발',
    member: [
      {
        image: '장동영.jpg',
        info: [
          '부문 PI',
          '장동영 교수',
          '서울대학교',
          '서울대학교 장동영 교수는 서울대학교 정수종 교수팀으로, 대기화학 모델링의 전문가로써 현재 서울대학교에서 메탄에 대한 다양한 관측 및 모델링 연구를 수행하고 있습니다. 본 과제에서 한국형 하이브리드 배출 인벤토리 산정을 담당합니다.',
        ],
      },
      {
        image: '손효종.jpg',
        info: [
          '참여연구원',
          '손효종 교수',
          '명지대학교',
          '명지대학교 송효종 교수팀은 자료동화 및 데이터분석 전문가로써 본 연구를 수행합니다.',
        ],
      },
      {
        image: '권형안.jpg',
        info: [
          '참여연구원',
          '권형안 교수',
          '수원대학교',
          '수원대학교 권형안 교수팀은 대기환경물질 및 온실가스 모델링 전문가로써 본 연구를 수행합니다.',
        ],
      },
      {
        image: '전원배.jpg',
        info: [
          '참여연구원',
          '전원배 교수',
          '부산대학교',
          '부산대학교 전원배 교수팀은 대기오염물질 모델링 및 인벤토리 분석 전문가로써 본 연구를 수행합니다.',
        ],
      },
      {
        image: '최우석.jpg',
        info: [
          '참여연구원',
          '최우석 교수',
          '세종대학교',
          '세종대학교 최우석 교수팀은 빅데이터를 이용한 기후응용 연구를 진행 중이며, 관련된 경험을 바탕으로 본 연구를 수행합니다.',
        ],
      },
      {
        image: '김화진.jpg',
        info: [
          '참여연구원',
          '김화진 교수',
          '서울대학교',
          '서울대학교 김화진 교수팀은 대기환경 물질 실측 및 인벤토리 연구에 관한 폭넓은 경험을 바탕으로 본 연구를 수행합니다.',
        ],
      },
    ],
  },
  {
    id: 3,
    title: '딥러닝 기반 미래 탄소 배출량 예측',
    member: [
      {
        image: '우정헌.jpg',
        info: [
          '부문 PI',
          '우정헌 교수',
          '서울대학교',
          '서울대학교 우정헌 교수팀은 미래 배출량 예측과 고해상도 배출 인벤토리를 구축하는 다양한 연구를 수행해 왔으며 이러한 경험을 기반으로 연구를 수행합니다.',
        ],
      },
      {
        image: '오혜연.jpg',
        info: [
          '참여연구원',
          '오혜연 교수',
          'KAIST',
          'KAIST 오혜연 교수는 시계열, 관계형 데이터 등에 관한 딥러닝 개발연구를 수행해 왔으며 이러한 경험을 기반으로 연구를 수행합니다.',
        ],
      },
      {
        image: 'xing_jia.jpg',
        info: [
          '참여연구원',
          'Xing Jia 교수',
          'George Masion University',
          'Geoge Mason University Xing Jia 교수는 중국 및 미국 지역에 딥러닝을 이요하여 대기오염 물질 배출량을 산정한 경험이 있으며, 통계자료 기반 배출량의 시공간 할당 관련 연구 등의 경험을 바탕으로 연구를 수행합니다.',
        ],
      },
      {
        image: '백복행.jpg',
        info: [
          '참여연구원',
          '백복행 교수',
          'George Masion University',
          'Geoge Mason University 백복행 교수는 중국 및 미국 지역에 딥러닝을 이요하여 대기오염 물질 배출량을 산정한 경험이 있으며, 통계자료 기반 배출량의 시공간 할당 관련 연구 등의 경험을 바탕으로 연구를 수행합니다.',
        ],
      },
    ],
  },
  {
    id: 4,
    title: '시공간 상세격자형 탄소 흡수량 산정 및 검증 기술 개발',
    member: [
      {
        image: '유가영.jpg',
        info: [
          '부문 PI',
          '유가영 교수',
          '경희대학교',
          '경희대학교 유가영 교수팀은 다년간 기후변화 및 생태계 탄소순환에 대한 다수의 온실 및 야외 실험 데이터 기반 모델링 연구를 폭넓게 수행해왔으며 이러한 경험을 기반으로 연구를 수행합니다.',
        ],
      },
      {
        image: '조재일.jpg',
        info: [
          '참여연구원',
          '조재일 교수',
          '전남대학교',
          '전남대학교 조재일 교수는 농경지 에디 타워 및 드론 관측 전문가로써 본 연구를 수행합니다.',
        ],
      },
      {
        image: '임정호.jpg',
        info: ['참여연구원', '임정호 교수', 'UNIST', 'UNIST 임정호 교수는 고해상도 위성/항공영상 분석 전문가로써 본 연구를 수행합니다.'],
      },
      {
        image: '김현석.jpg',
        info: [
          '참여연구원',
          '김현석 교수',
          '서울대학교',
          '서울대학교 김현석 교수는 자연녹지 에디 타워관측 및 식생연구 전문가로써 본 연구를 수행합니다.',
        ],
      },
      {
        image: '오능환.jpg',
        info: [
          '참여연구원',
          '오능환 교수',
          '서울대학교',
          '서울대학교 오능환 교수는 토양의 탄소 등 생지화학 순환 연구 전문가로써 본 연구를 수행한다.',
        ],
      },
      {
        image: '이양원.jpg',
        info: [
          '참여연구원',
          '이양원 교수',
          '부경대학교',
          '부경대학교 이양원 교수는 농경지 탄소 플럭스 데이터 기반 모델 및 위성영상 분석 전문가로써 본 연구를 수행합니다.',
        ],
      },
      {
        image: '강호정.jpg',
        info: [
          '참여연구원',
          '강호정 교수',
          '연세대학교',
          '연세대학교 강호정 교수는 토양 메탄 산화/배출 연구 전문가로써 본 연구를 수행합니다.',
        ],
      },
      {
        image: '박창의.jpg',
        info: ['참여연구원', '박창의 선임연구원', 'KIST', 'KIST 박창의 선임연구원은 과정 기반 모델 전문가로써 본 연구를 수행합니다.'],
      },
      {
        image: '박태원.jpg',
        info: ['참여연구원', '박태원 교수', '전남대학교', '전남대학교 박태원 교수는 과정 기반 모델 전문가로써 본 연구를 수행합니다.'],
      },
    ],
  },
  {
    id: 5,
    title: '딥러닝 기반 미래 탄소 흡수량 예측',
    member: [
      {
        image: '함유근.jpg',
        info: [
          '부문 PI',
          '함유근 교수',
          '서울대학교',
          '서울대학교 함유근 교수팀은 다년간 기후변화 및 예측에 대한 다수의 연구를 진행해 왔으며, 기후변화 및 예측 연구에 딥러닝 기법을 적용시켜 기후변화 불확실성 및 예측 성능을 향상시키고 있습니다. 해당 연구는 함유근 교수팀을 중심으로 이루어집니다.',
        ],
      },
      {
        image: '김은솔.jpg',
        info: [
          '참여연구원',
          '김은솔 교수',
          '한양대학교',
          '한양대학교 김은솔 교수는 멀티모달/시계열 데이터 분석 딥러닝 기법 연구에 특화되어 있으며 딥러닝 기법 구축 경험을 기반으로 연구를 수행합니다.',
        ],
      },
      {
        image: '김진수.jpg',
        info: [
          '참여연구원',
          '김진수 교수',
          '홍콩성시대',
          '홍콩성시대 김진수 교수는 식생 모델링 및 식생 관측 데이터 연구에 특화되어 있으며 식생 데이터 분석 경험을 기반으로 연구를 수행합니다.',
        ],
      },
    ],
  },
  {
    id: 6,
    title: '국가 및 지자체 탄소중립 이행평가를 위한 프레임 개발',
    member: [
      {
        image: '이승민.jpg',
        info: [
          '부문 PI',
          '이승민 박사',
          'KEI',
          'KEI 이승민 박사팀은 환경정책을 전문으로 하며 본 연구에서 산출된 다양한 과학적 자료를 통해 탄소수지의 정책 활용 및 탄소중립 평가이행을 진행한다.',
        ],
      },
    ],
  },
];

export const organizationsData: OrganizationsType[] = [
  {
    title: '참여대학 및 참여기관',
    description: '국내 대표 대학 및 연구기관의 참여를 통해 전문가 그룹을 형성하고 효율적 연구체계 갖춤',
    img: '/images/team/university.png',
  },
  {
    title: '국내 협력 기관',
    description: '국가 온실가스 협의체 소속 기관과의 협력을 통한 인벤토리 입력 자료 확보',
    img: '/images/team/domestic.png',
  },
  {
    title: '국외 협력 기관',
    description: '국외협력기관을 통한 온실가스 인공위성 관측자료 확보 및 국제 연구 협력',
    img: '/images/team/overseas.png',
  },
  {
    title: '민간 및 지자체',
    description: '배출원 감시를 위한 하이브리드 배출량 인벤토리 정보 공유 및 탄소중립 달성전략 수립 지원',
    img: '/images/team/local.png',
  },
];
