import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { IChartDataType } from 'interfaces/chartInterface';
import dayjs from 'dayjs';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Legend);

type Props = {
  data: { labels: string[]; datasets: IChartDataType[] };
};

const LineChart = ({ data }: Props) => {
  const options = {
    maintainAspectRatio: false,
    responsive: false,
    scales: {
      x: {
        border: { display: false },
        ticks: {
          padding: 10,
          callback: function (value, index, ticks) {
            return dayjs(data.labels[index]).format('YYYY.MM');
          },
        },
      },
      y: {
        grid: { display: false },
        ticks: {
          crossAlign: 'far' as 'far' | 'center' | 'near' | undefined,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          drag: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x' as 'x' | 'y' | 'xy',
        },
      },
    },
  };

  return <Line options={options} data={data} width={1200} height={350} style={{ position: 'relative', height: '350px', width: '100%' }} />;
};

export default LineChart;
