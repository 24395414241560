import { TCarbonSourceDataType, TGetMonthlyCarbonDataResponse, TGetTotalCarbonDataResponse } from 'interfaces/mapInterface';
import { commonAxios } from './commonApi';
import { AxiosResponse, all, spread } from 'axios';
import { stringify } from 'qs';

type TGetTotalCarbonDataParamsType = {
  startDate: string;
  endDate: string;
  location: {
    ctp_cd: number;
    sig_cd?: number;
    emd_cd?: number;
  };
  dataType: TCarbonSourceDataType[];
};

type TGetMonthlyCarbonDataParamsType = {
  yearMonth: string;
  ctp_cd: number;
  dataType: TCarbonSourceDataType[];
};

export const getTotalCarbonData = async (params: TGetTotalCarbonDataParamsType): Promise<TGetTotalCarbonDataResponse | undefined> => {
  const { location, ...rest } = params;
  const { ctp_cd, emd_cd, sig_cd } = location;
  try {
    const res = await commonAxios.get(`/carbon/total?${stringify({ ctp_cd, emd_cd, sig_cd, ...rest }, { arrayFormat: 'repeat' })}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getMonthlyCarbonData = async (params: TGetMonthlyCarbonDataParamsType): Promise<TGetMonthlyCarbonDataResponse | undefined> => {
  try {
    const res = await commonAxios.get(`/carbon/monthly?${stringify(params, { arrayFormat: 'repeat' })}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
