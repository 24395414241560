import { INetworkData } from 'interfaces/networkInterface';
import React from 'react';
import { Popup } from 'react-map-gl';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '@assets/icons/close_icon_15.svg';
import hexToRGBA from '@utils/hexToRGBA';
import { networkColumnData } from '@static/networkData';

type Props = {
  data: INetworkData;
  onClose: () => void;
};

const NetworkPopup = ({ data, onClose }: Props) => {
  return (
    <CustomPopup
      latitude={data.coordinates[1]}
      longitude={data.coordinates[0]}
      onClose={onClose}
      closeButton={false}
      closeOnClick={false}
      anchor="left"
      offset={[20, 110]}
    >
      <Content>
        <Header>
          <Title>정보</Title>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <TitleSector>
          <Marker $color={data.color} />
          <Text className="title">{data.location}</Text>
        </TitleSector>
        <MainSector>
          {data.equipments.map((item, idx) => {
            return (
              <MainItemGroup key={idx}>
                {Object.keys(item).map((col) => {
                  if (col === 'subname') return;
                  return (
                    <MainItem key={`${col}_${idx}`}>
                      <ColumnName>{networkColumnData[col]}</ColumnName>
                      <Text>{col === 'materials' ? item[col].join(', ') : item[col]}</Text>
                      {col === 'name' && Object.keys(item).includes('subname') && <Text>{`- ${item['subname']}`}</Text>}
                    </MainItem>
                  );
                })}
              </MainItemGroup>
            );
          })}
        </MainSector>
      </Content>
    </CustomPopup>
  );
};

export default React.memo(NetworkPopup);

const MainSector = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow-y: auto;
  max-height: 390px;

  padding: 0px 10px 10px 15px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    height: 30%;
    background-color: ${({ theme }) => theme.newColors.grey1};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const MainItemGroup = styled.div`
  & + & {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid ${({ theme }) => hexToRGBA(theme.newColors.grey1, 0.5)};
  }
`;

const MainItem = styled.div`
  & + & {
    margin-top: 20px;
  }
`;

const ColumnName = styled.div`
  color: ${({ theme }) => theme.newColors.white};
  font-family: 'SUIT Variable';
  font-size: 16px;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;

  border-radius: 3px;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.grey1, 0.5)};

  padding: 5px 10px;
  width: fit-content;

  margin-bottom: 10px;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;

  &.title {
    font-size: 18px;
    font-weight: 700;
    line-height: 30px; /* 166.667% */
    letter-spacing: -0.36px;
  }

  & + & {
    margin-top: 5px;
  }
`;

const TitleSector = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 10px 10px 0px 10px;
  margin: 20px 0px 30px 0px;
  width: 100%;
`;

const Marker = styled.div<{ $color: string }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;

  background-color: ${({ $color }) => $color};
  border: 2px solid ${({ $color }) => hexToRGBA($color, 0.3)};
  background-clip: padding-box;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  padding: 10px 10px 0px 10px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.newColors.white};
  font-size: 18px;
  font-weight: 600;
  line-height: 22px; /* 122.222% */
`;

const CloseButton = styled.button``;

const Content = styled.div`
  display: flex;
  width: 300px;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark3, 0.7)};
  box-shadow: 2px 5px 10px 0px ${({ theme }) => hexToRGBA(theme.newColors.black, 0.3)};
  backdrop-filter: blur(10px);
`;

const CustomPopup = styled(Popup)`
  .mapboxgl-popup-tip {
    border: none;
  }

  .mapboxgl-popup-content {
    background: transparent;
    border-radius: 5px;
    padding: 0px;
  }
`;
