export const ChartData = [
  {
    title: '그룹 1',
    borderColor: '#ff8718',
    backgroundColor: 'rgba(255, 92, 0, 0.5)',
  },
  {
    title: '그룹 2',
    borderColor: '#9E71FF',
    backgroundColor: 'rgba(158, 113, 255,0.5)',
  },
  {
    title: '그룹 3',
    borderColor: '#60BCFF',
    backgroundColor: 'rgba(96, 188, 255, 0.5)',
  },
];
