import hexToRGBA from '@utils/hexToRGBA';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  large?: boolean;
  disabled?: boolean;
}

const CustomCheckbox = forwardRef<HTMLInputElement, Props>(({ id, label, large = false, disabled = false, ...props }, ref) => {
  return (
    <Container $disabled={disabled}>
      <CheckboxInput id={id} type="checkbox" disabled={disabled} $large={large} ref={ref} {...props} />
      {label && <Label htmlFor={id}>{label}</Label>}
    </Container>
  );
});

export default CustomCheckbox;

const Label = styled.label`
  flex: 1;
  font-size: 16px;
  font-variation-settings: 'wght' 400;
  line-height: 20px;
  letter-spacing: -0.32px;
  text-align: left;
  padding-left: 10px;
`;

const CheckboxInput = styled.input<{ $large: boolean }>`
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.checkboxBorder};
  background-color: ${({ theme }) => theme.colors.white};

  &::after {
    content: '';
    display: none;
    width: 3px;
    height: 5px;
    margin: 2px auto;
    border: solid white;
    border-width: 0px 1.5px 1.5px 0px;
    transform: rotate(45deg);

    ${(props) =>
      props.$large &&
      `width: 20px;
      height: 20px;

    &::after {
      width: 5px;
      height: 8px;
      margin: 2px auto;
    }`}
  }

  &:checked {
    background-color: ${({ theme }) => theme.colors.orange};

    &::after {
      display: block;
    }
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.checkboxDisabledBg};

    ${Label} {
      cursor: default;
    }
  }
`;

const Container = styled.div<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 17px;
  cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};

  ${Label} {
    color: ${(props) => (props.$disabled ? hexToRGBA(props.theme.colors.text, 0.5) : props.theme.colors.text)};
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
  }

  ${CheckboxInput} {
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
  }
`;
