import styled from 'styled-components';
import { ReactComponent as ChevronLeftIcon } from '@assets/icons/chevron_left_icon.svg';
import { ReactComponent as ChevronRightIcon } from '@assets/icons/chevron_right_icon.svg';
import { ReactComponent as PlayIcon } from '@assets/icons/panel/play_icon.svg';
import { ReactComponent as PauseIcon } from '@assets/icons/panel/pause_icon.svg';
import MapCommonButton from '@components/common/MapCommonButton';
import { useMemo, useState } from 'react';
import RangeSlider from '@components/common/RangeSlider';
import useInterval from '@hooks/useInterval';
import useMapStore from 'stores/mapStore';
import { newColors } from '@styles/theme';
import hexToRGBA from '@utils/hexToRGBA';

const TimelapsePanel = () => {
  const { selectDataCondition, activeYearMonth, updateTimelapseMonth } = useMapStore();
  const [isPlaying, setIsPlaying] = useState(false);
  const totalMonth = useMemo(() => {
    return selectDataCondition.endDate.diff(selectDataCondition.startDate, 'month') || 0;
  }, [selectDataCondition]);

  const handlePlayButtonClick = () => {
    setIsPlaying((prev) => !prev);
  };

  const handlePreviousButtonClick = () => {
    const prev = activeYearMonth.diff(selectDataCondition.startDate, 'month') || 0;
    updateTimelapseMonth(prev === 0 ? 0 : prev - 1);
  };

  const handleForwardButtonClick = () => {
    const prev = activeYearMonth.diff(selectDataCondition.startDate, 'month') || 0;
    updateTimelapseMonth(prev === totalMonth ? totalMonth : prev + 1);
  };

  const handleChange = (value: number) => {
    updateTimelapseMonth(value);
  };

  useInterval(
    () => {
      const prev = activeYearMonth.diff(selectDataCondition.startDate, 'month') || 0;
      updateTimelapseMonth(prev === totalMonth ? 0 : prev + 1);
    },
    isPlaying ? 1000 : null,
  );

  return (
    <Container>
      <ButtonContainer>
        <MapCommonButton icon={<ChevronLeftIcon />} type="small" isHover={true} onClick={handlePreviousButtonClick} />
        <MapCommonButton icon={isPlaying ? <PauseIcon /> : <PlayIcon />} type="small" isHover={true} onClick={handlePlayButtonClick} />
        <MapCommonButton icon={<ChevronRightIcon />} type="small" isHover={true} onClick={handleForwardButtonClick} />
      </ButtonContainer>
      <RangeSlider
        min={0}
        max={totalMonth}
        value={activeYearMonth.diff(selectDataCondition.startDate, 'month') || 0}
        filledColor={newColors.primary}
        handleChange={handleChange}
      />
      <DateSpan>{activeYearMonth.format('YYYY.MM')}</DateSpan>
    </Container>
  );
};

export default TimelapsePanel;

const Container = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 645px;
  padding: 15px;
  border-radius: 5px;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark1, 0.5)};
  backdrop-filter: blur(4px);
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  button {
    background-color: ${({ theme }) => theme.newColors.dark3};

    &:hover {
      color: ${({ theme }) => theme.newColors.white};
    }
  }
`;

const DateSpan = styled.span`
  width: 55px;
  min-width: 55px;
  font-size: 14px;
  font-variation-settings: 'wght' 500;
  letter-spacing: -0.28px;
`;
