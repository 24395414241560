import { aboutData } from '@static/aboutData';
import hexToRGBA from '@utils/hexToRGBA';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '@assets/icons/chevron_right_icon.svg';

const Roadmap = () => {
  const data = aboutData.roadmap.roadmap;
  return (
    <Container>
      <MapContainer>
        {data.map.map((item) => (
          <Map key={item.title}>
            <div className="title">{item.title}</div>
            <div className="image">
              <img src={item.src} alt={`${item.title} 지도`} />
            </div>
          </Map>
        ))}
      </MapContainer>
      <Timeline>
        <div className="line">
          {data.history.map((item) => (
            <TimelineEvent key={item.year}>
              <div className="circle"></div>
              <div className="text">
                <div className="year">{item.year}</div>
                <div>{item.text1}</div>
                <div>{item.text2}</div>
              </div>
            </TimelineEvent>
          ))}
        </div>
      </Timeline>
      <ContentContainer>
        {data.plan.map((item) => (
          <Content key={item.title}>
            <div className="title">{item.title}</div>
            <div className="items">
              {item.data.map((subitem, idx) => (
                <Fragment key={subitem}>
                  <div className="item">{subitem}</div>
                  {item.data.length !== idx + 1 && <ArrowIcon />}
                </Fragment>
              ))}
            </div>
          </Content>
        ))}
      </ContentContainer>
    </Container>
  );
};

export default Roadmap;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const MapContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 22.5px;
`;

const Map = styled.div`
  border-radius: 5px;
  background: ${({ theme }) => hexToRGBA(theme.newColors.dark2, 0.5)};

  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const Timeline = styled.div`
  height: 126px;
  width: calc(100vw - 17px);
  min-width: 1248px;
  margin-top: 100px;
  margin-bottom: 50px;
  margin-left: min(0px, calc((1248px - (100vw - 17px)) / 2));

  div.line {
    background-color: ${({ theme }) => theme.newColors.primary};
    height: 3px;

    display: flex;
    align-items: start;
    justify-content: center;

    gap: 20px;
    padding-left: 130px;
  }
`;

const TimelineEvent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -8px;
  gap: 20px;
  width: 200px;

  div.circle {
    width: 20px;
    height: 20px;
    border-radius: 50px;

    background-color: ${({ theme }) => theme.newColors.white};
    border: 4px solid ${({ theme }) => theme.newColors.primary};
  }

  div.text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    div {
      color: ${({ theme }) => theme.newColors.white};

      font-size: 18px;
      font-variation-settings: 'wght' 600;
      line-height: 22px; /* 122.222% */
    }

    div.year {
      color: ${({ theme }) => theme.newColors.primary};
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  div.title {
    color: ${({ theme }) => theme.newColors.white};
    font-size: 25px;
    font-variation-settings: 'wght' 800;
    line-height: 40px; /* 160% */
    letter-spacing: -0.5px;

    margin-right: 20px;
  }

  div.items {
    display: flex;
    align-items: center;
    gap: 5px;
    flex: 1 1 0;

    div.item {
      display: flex;
      min-width: 200px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      padding: 12px;
      background-color: ${({ theme }) => theme.newColors.dark3};

      color: ${({ theme }) => theme.newColors.white};
      font-size: 16px;
      font-variation-settings: 'wght' 600;
      line-height: 20px; /* 125% */
      letter-spacing: -0.32px;

      &:nth-last-child(1) {
        flex: 1 1 0;
      }
    }
  }
`;
