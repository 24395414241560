import React, { useState } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  icon: React.ReactNode;
  onClick?: () => void;
  type?: 'small' | 'default';
  bgColorType?: 'dark' | 'default';
  isHover?: boolean;
  isSelected?: boolean;
  defaultSelected?: boolean;
};

const MapCommonButton = ({
  icon,
  onClick,
  type = 'default',
  bgColorType = 'default',
  isHover = false,
  isSelected = false,
  defaultSelected = false,
}: Props) => {
  const [selected, setSelected] = useState<boolean>(defaultSelected);

  const onClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick && onClick();
    setSelected((prev) => !prev);
  };

  return (
    <Container onClick={onClickHandler} $type={type} $isHover={isHover} $isSelected={isSelected && selected} $bgColorType={bgColorType}>
      {icon}
    </Container>
  );
};

export default MapCommonButton;

const Container = styled.button<{ $type: 'small' | 'default'; $isHover: boolean; $isSelected: boolean; $bgColorType: 'dark' | 'default' }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme, $bgColorType }) => ($bgColorType === 'dark' ? theme.newColors.dark3 : theme.newColors.grey1)};
  box-shadow: ${({ $bgColorType }) =>
    $bgColorType === 'dark'
      ? '0px 3px 5px 0px rgba(0, 0, 0, 0.15), 1px 1px 1px 0px rgba(155, 161, 178, 0.3) inset'
      : '1px 1px 1px 0px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.15)'};
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.$type === 'small' &&
    css`
      width: 30px;
      height: 30px;
    `}

  ${(props) =>
    props.$isHover &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.newColors.primary};
        svg rect {
          fill: ${({ theme }) => theme.newColors.white};
        }
      }
    `}

  ${(props) =>
    props.$isSelected &&
    css`
      background-color: ${({ theme }) => theme.newColors.primary};
    `}
`;
