import hexToRGBA from '@utils/hexToRGBA';
import React from 'react';
import styled from 'styled-components';

export interface IMemberInfo {
  image: string;
  info: string[];
}

type Props = {
  member: IMemberInfo;
};

const MemberItem = ({ member }: Props) => {
  return (
    <Container>
      <Image name={member.image} />
      <TextWrapper>
        <Text>{member.info[0]}</Text>
        <Text>{`${member.info[2]} ${member.info[1]}`}</Text>
        <Text className="text">{member.info[3]}</Text>
      </TextWrapper>
    </Container>
  );
};

export default MemberItem;

const Image = styled.div<{ name: string }>`
  border-radius: 5px;
  min-width: 135px;
  height: 189px;

  background-image: url(${(props) => `/images/profile/${props.name}`});
  background-size: cover;
  background-repeat: no-repeat;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.newColors.white};

  font-size: 18px;
  font-variation-settings: 'wght' 600;
  line-height: 22px; /* 122.222% */

  &.text {
    font-size: 16px;
    font-variation-settings: 'wght' 400;
    line-height: 28px; /* 175% */
    letter-spacing: -0.32px;
    margin-top: 10px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  padding: 20px;
  border-radius: 5px;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark2, 0.5)};
`;
