import { profile } from '@static/teamData';
import React, { useState } from 'react';
import styled from 'styled-components';
import MemberGroup from './MemberGroup';

type Props = {};

const MemberList = (props: Props) => {
  const [select, setSelect] = useState<number>();

  return (
    <Container>
      {profile.map((item) => (
        <MemberGroup
          key={item.id}
          id={item.id}
          title={item.title}
          member={item.member}
          onClick={setSelect}
          selected={select ? select === item.id : undefined}
        />
      ))}
    </Container>
  );
};

export default MemberList;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 1248px;
  gap: 10px;
`;
