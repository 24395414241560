import MapCommonButton from '@components/common/MapCommonButton';
import { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DataResultIcon } from '@assets/map/data_setting.svg';
import useMapStore from 'stores/mapStore';
import Legend from '@components/map/resultPanel/Legend';
import hexToRGBA from '@utils/hexToRGBA';
import { ReactComponent as CloseIcon } from '@assets/icons/close_icon_15.svg';
import ResultCarbonItem from '@components/map/resultPanel/ResultCarbonItem';
import { getLocationNameByObject } from '@utils/getLocationName';
import { TGetTotalCarbonDataResponse } from 'interfaces/mapInterface';

type TProps = {
  data: TGetTotalCarbonDataResponse;
};

const ResultPanel = ({ data }: TProps) => {
  const { selectDataCondition } = useMapStore();
  const [isPanelOpen, setIsPanelOpen] = useState(true);

  return (
    <Container>
      {!isPanelOpen && <MapCommonButton icon={<DataResultIcon />} onClick={() => setIsPanelOpen(true)} isHover={true} />}
      {isPanelOpen && selectDataCondition.dataType && (
        <PanelContainer $isOpen={isPanelOpen}>
          <CloseButton onClick={() => setIsPanelOpen(false)}>
            <CloseIcon />
          </CloseButton>
          <Title>범례</Title>
          <Legend carbonType={selectDataCondition.dataType} />
          <Hr />
          <LocationList>
            {getLocationNameByObject(selectDataCondition.location, data).map((item) => {
              return (
                <li key={item.title}>
                  <ResultCarbonItem location={item.title} carbonAmount={item.value} />
                </li>
              );
            })}
          </LocationList>
        </PanelContainer>
      )}
    </Container>
  );
};

export default ResultPanel;

const Container = styled.div`
  position: absolute;
  left: 10px;
  bottom: 40px;
  z-index: 2;
`;

const PanelContainer = styled.div<{ $isOpen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 270px;
  padding: 15px;
  background: ${({ theme }) => hexToRGBA(theme.newColors.dark1, 0.8)};
  box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
`;

const Title = styled.span`
  font-size: 18px;
  font-variation-settings: 'wght' 600;
  line-height: 22px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
`;

const Hr = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  background-color: ${({ theme }) => theme.newColors.dark3};
`;

const LocationList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
