import styled from 'styled-components';

type Props = {
  imgSrc: string;
  bottom: number;
};

const SwiperItem = ({ imgSrc, bottom }: Props) => {
  return (
    <Container $bottom={bottom}>
      <img src={imgSrc} />
    </Container>
  );
};

export default SwiperItem;

const Container = styled.div<{ $bottom: number }>`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: end;
  justify-content: center;
  padding-bottom: ${({ $bottom }) => `${$bottom}px`};

  img {
    object-fit: contain;
  }
`;
