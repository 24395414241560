import { TCarbonSourceDataType } from 'interfaces/mapInterface';
import { commonAxios } from './commonApi';
import { stringify } from 'qs';
import { TGetCarbonChartDataResponse, TGetCarbonRankingDataResponse } from 'interfaces/dashboardInterface';
import { all } from 'axios';

type TGetCarbonRankingDataParamsType = {
  ctp_cd: number;
  type: 'ctp' | 'sig';
  data: number;
  dataType: TCarbonSourceDataType[];
};

type TGetCarbonChartDataParamsType = {
  startDate: string;
  endDate: string;
  location: {
    ctp_cd: number;
    sig_cd?: number;
    emd_cd?: number;
  }[];
  dataType: TCarbonSourceDataType[];
};

export const getCarbonRankingData = async (param: TGetCarbonRankingDataParamsType): Promise<TGetCarbonRankingDataResponse | undefined> => {
  try {
    const res = await commonAxios.get(`/carbon/dashboard/rank?${stringify(param, { arrayFormat: 'repeat' })}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getCarbonChartData = async (param: TGetCarbonChartDataParamsType): Promise<TGetCarbonChartDataResponse[] | undefined> => {
  const { location, ...rest } = param;
  try {
    const res = await all(
      location.map((item) => {
        const { ctp_cd, sig_cd, emd_cd } = item;
        const type = emd_cd ? 'emd' : sig_cd ? 'sig' : 'ctp';
        const data = emd_cd ? emd_cd : sig_cd ? sig_cd : ctp_cd;

        return commonAxios.get(`/carbon/dashboard/compare?${stringify({ type, data, ...rest }, { arrayFormat: 'repeat' })}`);
      }),
    );
    return res.map((item) => item.data);
  } catch (err) {
    console.log(err);
  }
};
