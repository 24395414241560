import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
      box-sizing:border-box;
      font-family: 'SUIT';
    }
  html,body {
      margin:0;
      padding:0;
      font-family: 'SUIT';
      background-color: ${({ theme }) => theme.newColors.dark1};
      color: ${({ theme }) => theme.newColors.white};
  }
  html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video, button {
    margin: 0;
    padding: 0;
    font-weight: unset;
    }
    ul,li{
        list-style:none;
        margin:0;
        padding:0;
    }
    hr {
        margin: 0;
    }
    input {
        outline: none;
        padding: 0;
        margin: 0;
    }
    textarea {
        outline: none;
        margin: 0;
    }
    a {
      text-decoration:none;
      color:inherit;
    }
    button {
        color: inherit;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
    }
`;

export default GlobalStyle;
