import { useEffect, useState } from 'react';

/**
 *
 * @param elementId 열림 상태를 유지할 요소의 id 선택자
 * @param initialState 초기 상태값. default: false
 */
const useCloseWhenOutsideClick = (elementId: string, initialState?: boolean) => {
  const [isOpen, setIsOpen] = useState(initialState !== undefined ? initialState : false);

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      // 클릭한 요소가 elementId거나 elementId의 자식이면 isOpen true로 유지
      const element = document.querySelector(elementId);
      if (!element || (e.target && element.contains(e.target as HTMLElement))) return;
      handleClose();
    };

    isOpen ? window.addEventListener('click', handleClick) : window.removeEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [elementId, isOpen]);

  return [isOpen, setIsOpen] as const;
};

export default useCloseWhenOutsideClick;
