import { locationData } from '@static/locationData';
import { TGetTotalCarbonDataResponse, TLocationDataType } from 'interfaces/mapInterface';

export const getLocationName = (keyList: (number | undefined)[], currentIndex: number) => {
  const indexList = [0, 1, 2];
  if (!indexList.includes(currentIndex)) return '오류';
  const targetList = keyList.map((item) => (item ? item : 0));

  switch (currentIndex) {
    case 0:
      return locationData[targetList[0].toString()].ko;
    case 1:
      return locationData[targetList[0].toString()].children[targetList[1].toString()].ko;
    case 2:
      return locationData[targetList[0].toString()].children[targetList[1].toString()].children[targetList[2].toString()].ko;
  }
};

export const getLocationNameList = (location: TLocationDataType, isThirdItem: boolean) => {
  const target = Object.keys(location);
  if (target.length === 0) return '지역 선택';
  if (target.length === 3 && !isThirdItem)
    return target
      .slice(0, 2)
      .map((_, index) => getLocationName(Object.values(location), index))
      .join(', ');
  else return target.map((_, index) => getLocationName(Object.values(location), index)).join(', ');
};

export const getLocationNameByObject = (location: TLocationDataType, data: TGetTotalCarbonDataResponse) => {
  const result: { title: string; value: number }[] = [];

  result.push({ title: locationData[location.ctp_cd].ko, value: data['ctp_cd'] });
  location.sig_cd &&
    data['sig_cd'] &&
    result.push({ title: locationData[location.ctp_cd]['children'][location.sig_cd].ko, value: data['sig_cd'] });
  location.sig_cd &&
    location.emd_cd &&
    data['emd_cd'] &&
    result.push({
      title: locationData[location.ctp_cd]['children'][location.sig_cd]['children'][location.emd_cd].ko,
      value: data['emd_cd'],
    });

  return result;
};
